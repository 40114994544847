import { Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { getStaticsEvent } from '../../services/event.services';
import Loading from '../Loading';
import TableUsersRegisterEvent from './TableUsersRegisterEvent';
import '../../assets/styles/components/Event/StaticsEvent.scss';

const StaticsEvent = (props) => {

  const idEvent = props.match.params.id;
  const [showLoader, setShowLoader] = useState(false);
  const [usersRegister, setUserRegister] = useState([]);

  const getInitialData = async () => {
    setShowLoader(true);

    const responseStatics = await getStaticsEvent(idEvent);

    setUserRegister(responseStatics.users_register_event);

    setShowLoader(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackPage = () => { props.history.goBack(); };

  return (
    <>
      <Grid container component='main' className='container-header-page-url'>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <div>
            <Typography>
              <span className='text-header-page-url'>
                Evento / Estadisticas
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} className=''>
          <div className='mobile-back-btn container-create-event'>
            <ArrowBackIcon className='btn-back-page' onClick={handleBackPage} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} className=''>
          <TableUsersRegisterEvent usersRegister={usersRegister} setShowLoader={setShowLoader} />
        </Grid>
      </Grid>

      {showLoader && (
        <div className='container-loader-carousel'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default StaticsEvent;
