import {
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { getMaterialsEvent } from '../../../services/event.services';
import AutocompleteSearch from '../../CustomBashComponents/AutocompleteSearch';
import DropZone from '../../User/DropZone';

const StepImage = (props) => {
  const { form, setLoading, handleChange } = props;

  const [imgEvent, setImgEvent] = useState(form.image);
  const [imgFile, setImgFile] = useState(null);
  const [openFacebook, setOpenFacebook] = useState(!!form.facebook);
  const [openTwitter, setOpenTwitter] = useState(!!form.twitter);
  const [openInstagram, setOpenInstagram] = useState(!!form.instagram);

  const handleSetImage = (imageB64, file) => {
    setImgEvent(imageB64);
    setImgFile(file);
  };

  const handleAddLabel = (label) => {
    console.log(`Se va a agregar el material ${label}`);

    form.materials.push(label);

    handleChange({
      target: {
        name: 'materials',
        value: form.materials,
      },
    });
  };

  const handleDeleteItem = (itemId) => {
    form.materials = form.materials.filter((item) => {
      return item.value !== itemId;
    });

    handleChange({
      target: {
        name: 'materials',
        value: form.materials,
      },
    });
  };

  useEffect(() => {
    handleChange({ target: { name: 'imageFile', value: imgFile } });
  }, [imgFile]);

  useEffect(() => {
    handleChange({ target: { name: 'image', value: imgEvent } });
  }, [imgEvent]);

  return (
    <>
      <Grid container component="section" className="">
        <Grid item xs={12} sm={12} md={2} xl={2} />
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          xl={8}
          className="container-description"
        >
          <Typography className="title-step mb-3">
            Sube una imagen que represente tu evento
          </Typography>
          <div className="container-img-event">
            <DropZone
              img={imgEvent}
              setImage={handleSetImage}
              setLoading={setLoading}
            />
          </div>
          <Typography className="sub-title-bash-step color-ligth-text-bash mt-2 mb-3">
            Te recomendamos que la imagen sea de 3MB en jpg o png
          </Typography>

          <Typography className="title-step mt-3 sub-title-bold">
            Descripción
          </Typography>
          <Typography className="sub-title-step">
            Ingresa la descripción e información clave de tu evento
          </Typography>
          <Grid
            container
            component="div"
            className="container-description mt-3 mb-3"
          >
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <TextareaAutosize
                className="text-area-description"
                rowsMin={6}
                id="description"
                name="description"
                placeholder="Descripción"
                value={form.description}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>

          <Typography className="sub-title-step mt-3 sub-title-bold">
            Tu evento necesita algun material o objeto
          </Typography>
          <AutocompleteSearch
            handleSearchService={getMaterialsEvent}
            labelsSelected={form.materials}
            handleAddLabel={handleAddLabel}
            handleDeleteItem={handleDeleteItem}
            keyId="search-types"
          />

          <Typography className="align-left sub-title-step mt-3">
            Comparte tus redes sociales o página del evento
          </Typography>
          <div className="container-list-social mt-3">
            <div
              className={`container-icon-social ${
                openFacebook ? 'active' : ''
              }`}
            >
              <FacebookIcon
                className={`icon-social-share ${openFacebook ? 'active' : ''}`}
                onClick={() => {
                  if (!openFacebook) {
                    handleChange({ target: { name: 'facebook', value: '' } });
                  }
                  setOpenFacebook(!openFacebook);
                }}
              />
            </div>
            <div
              className={`container-icon-social ${openTwitter ? 'active' : ''}`}
            >
              <TwitterIcon
                className={`icon-social-share ${openTwitter ? 'active' : ''}`}
                onClick={() => {
                  if (!openTwitter) {
                    handleChange({ target: { name: 'twitter', value: '' } });
                  }
                  setOpenTwitter(!openTwitter);
                }}
              />
            </div>
            <div
              className={`container-icon-social ${
                openInstagram ? 'active' : ''
              }`}
            >
              <InstagramIcon
                className={`icon-social-share ${openInstagram ? 'active' : ''}`}
                onClick={() => {
                  if (!openInstagram) {
                    handleChange({ target: { name: 'instagram', value: '' } });
                  }
                  setOpenInstagram(!openInstagram);
                }}
              />
            </div>
          </div>

          {openFacebook && (
            <div className="row">
              <Typography className="align-left mt-3">Facebook</Typography>
              <TextField
                id="facebook"
                name="facebook"
                className="input-autocomplete"
                value={form.facebook}
                onChange={handleChange}
                color="primary"
                required={openFacebook}
              />
            </div>
          )}

          {openTwitter && (
            <div className="row">
              <Typography className="align-left mt-3">Twitter</Typography>
              <TextField
                id="twitter"
                name="twitter"
                className="input-autocomplete"
                value={form.twitter}
                onChange={handleChange}
                color="primary"
                required={openTwitter}
              />
            </div>
          )}

          {openInstagram && (
            <div className="row">
              <Typography className="align-left mt-3">Instagram</Typography>
              <TextField
                id="instagram"
                name="instagram"
                className="input-autocomplete"
                value={form.instagram}
                onChange={handleChange}
                color="primary"
                required={openInstagram}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} xl={2} />
      </Grid>
    </>
  );
};

export default StepImage;
