import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';

import { connect } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { setUserRegister } from '../../actions/index';
import { registerUser } from '../../services/authentication-services';
import ROUTES from '../../utils/RouteUtils';
import Loading from '../Loading';
import MailModal from './MailModal';

const Register = (props) => {

  const {
    user,
    setUserRegister,
  } = props;

  const [error, setError] = useState(false);
  const [errorConfirmEmail, setErrorConfirmEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState({
    email: user.email,
    confirmEmail: '',
    name: '',
    password: '',
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSetErrorState = () => setError(true);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const confirmEmail = () => {
    console.log('Confirm Email');
    props.history.push(ROUTES.MORE_INFORMATION);
  };

  const submitForm = (event) => {
    setLoading(true);
    event.preventDefault();
    if (form.email !== form.confirmEmail) {
      setErrorConfirmEmail(true);
      setLoading(false);
    } else {
      console.log('On submit form register');
      setErrorConfirmEmail(false);

      registerUser(form).then((success) => {
        console.log('Success register');
        console.log(success);
        setUserRegister(success.data.data.token, form);
        handleShowModal();
      }).catch((error) => {
        console.log('Error register');
        console.log(error.response);
        handleSetErrorState();
      }).finally(() => {
        setLoading(false);
      });
    }

  };

  return (
    <>
      <div>
        <div className='login-bash'>
          <span className='welcome-bash'>Crea una cuenta</span>
        </div>
      </div>
      <form id='ready_form' name='ready_form' className='' autoComplete='off' onSubmit={submitForm}>
        <div className='container-input'>
          <div className='grid-inputs'>
            <TextField onChange={handleChange} error={error} color='primary' className='input-login' id='email' label='email' name='email' type='email' value={user.email} disabled required />
          </div>
          <div className='grid-inputs'>
            <TextField onChange={handleChange} error={error || errorConfirmEmail} color='primary' className='input-login' id='email' label='Confirma email' name='confirmEmail' type='email' required />
          </div>
          <div className='grid-inputs'>
            <TextField onChange={handleChange} error={error} color='primary' className='input-login' id='name' label='Nombre y Apellido' name='name' type='text' required />
          </div>
          <div className='grid-inputs'>
            <TextField
              onChange={handleChange}
              error={error}
              color='primary'
              className='input-login'
              id='password'
              label='password'
              name='password'
              type={showPassword ? 'text' : 'password'}
              required
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Button type='submit' className='btn-login-bash' variant='contained' color='primary'>INGRESAR</Button>
      </form>
      <div className='login-bash'>
        <span className='forgot-passsword'>
          ¿Ya tienes cuenta?
          <Link to={ROUTES.READY_LOGIN}>INICIAR SESIÓN</Link>
        </span>
      </div>
      <MailModal
        show={showModal}
        handleCloseModal={handleCloseModal}
        handleClick={confirmEmail}
      />
      {loading && <Loading />}
    </>
  );

};

const mapPropsToDispatch = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setUserRegister,
};

export default connect(mapPropsToDispatch, mapDispatchToProps)(Register);
