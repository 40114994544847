import { getCategories } from '../api/categories';
import EventsCategories from '../json/events-categories.json';
import {
  getEventsByCategories,
  getEventsByUserId,
  searchEventsByFilter,
} from '../services/event.services';
import { getBannerImages } from '../services/menu-services';
import { buildFilters, splitEventsUser } from '../utils';
import {
  GET_BANNER_IMAGES,
  GET_CATEGORIES,
  GET_EVENTS,
  GET_EVENTS_ERROR,
  GET_USER_EVENTS,
  SET_ALERT,
  SET_EMAIL,
  SET_FILTERED_EVENTS,
  SET_INTERESTS_EVENTS,
  SET_LOADING_SCREEN,
  SET_SUCCESS_MESSAGE,
  SET_TOKEN_SOCIAL_LOGIN,
  SET_USER,
  SET_USER_REGISTER,
  SOCIAL_LOGIN,
  USER_LOGOUT,
  TOGGLE_FAVORITE
} from '../utils/ActionsUtils';

const withoutServices = process.env.REACT_APP_WITHOUT_SERVICES === 'true';

export const setUser = (payload) => {
  return async function (dispatch) {
    dispatch({
      type: SET_USER,
      payload,
    });
  };
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });
};

export const setAlert = (payload) => (dispatch) => {
  dispatch({
    type: SET_ALERT,
    payload,
  });
};

export const getBanners = () => async (dispatch) => {
  try {
    const response = await getBannerImages();

    dispatch({
      type: GET_BANNER_IMAGES,
      payload: response.images,
    });
  } catch (error) {
    dispatch({
      type: GET_BANNER_IMAGES,
      payload: [],
    });
  }
};

export const getEventsCategories = (payload) => {
  return async function (dispatch) {
    let events = {};

    if (withoutServices) {
      events = EventsCategories.events;
    }

    dispatch({
      type: GET_EVENTS,
      payload: events,
    });
  };
};

export const addEventsCategories = (payload) => {
  return async function (dispatch) {
    dispatch({
      type: SET_LOADING_SCREEN,
      payload: true,
    });

    const responseCategories = await getCategories(
      { limit: null },
      payload.token
    );

    if (!responseCategories.status) {
      dispatch({
        type: GET_EVENTS_ERROR,
        payload: responseCategories.error,
      });
      dispatch({
        type: SET_LOADING_SCREEN,
        payload: false,
      });
      return;
    }

    const categories = responseCategories.data;
    const totalCategories = responseCategories.total;

    dispatch({
      type: GET_CATEGORIES,
      payload: {
        categories,
        total_categories: totalCategories,
      },
    });

    const response = await getEventsByCategories(
      categories.slice(0, payload.count),
      payload.token
    );

    if (!response.status) {
      dispatch({
        type: GET_EVENTS_ERROR,
        payload: response.error,
      });
      dispatch({
        type: SET_LOADING_SCREEN,
        payload: false,
      });

      return;
    }

    dispatch({
      type: GET_EVENTS,
      payload: { ...response.data },
    });
    dispatch({
      type: SET_LOADING_SCREEN,
      payload: false,
    });
  };
};

export const getListCategories = (payload) => {
  return async function (dispatch) {
    dispatch({
      type: SET_LOADING_SCREEN,
      payload: true,
    });

    const responseCategories = await getCategories(
      { limit: null },
      payload.token
    );
    if (!responseCategories.status) {
      dispatch({
        type: GET_EVENTS_ERROR,
        payload: responseCategories.error,
      });
      dispatch({
        type: SET_LOADING_SCREEN,
        payload: false,
      });
      return;
    }

    const categories = responseCategories.data;
    const totalCategories = responseCategories.total;

    dispatch({
      type: GET_CATEGORIES,
      payload: {
        categories,
        total_categories: totalCategories,
      },
    });
  };
};

export const loginFacebook = (payload, loginSocialSuccess) => {
  return async function (dispatch) {
    const payloadTransform = {
      name: payload.name,
      email: payload.email,
      image: payload.picture.data.url,
      access_token_social: payload.accessToken,
      provider: 'facebook',
    };

    loginSocialSuccess({
      access_token_social: payload.accessToken,
      provider: 'facebook',
    });
    dispatch({
      type: SOCIAL_LOGIN,
      payload: payloadTransform,
    });
  };
};

export const loginGoogle = (payloadGoogle, loginSocialSuccess) => {
  return async function (dispatch) {
    const payload = {
      name: payloadGoogle.profileObj.name,
      email: payloadGoogle.profileObj.email,
      image: payloadGoogle.profileObj.imageUrl,
      access_token_social: payloadGoogle.accessToken,
      provider: 'google',
    };

    loginSocialSuccess({
      access_token_social: payloadGoogle.accessToken,
      provider: 'google',
    });

    dispatch({
      type: SOCIAL_LOGIN,
      payload,
    });
  };
};

export const setEmail = (payload) => {
  return async function (dispatch) {
    console.log(payload);
    dispatch({
      type: SET_EMAIL,
      payload,
    });
  };
};

export const setUserRegister = (token, userData) => {
  return async function (dispatch) {
    const userPayload = {
      name: userData.name,
      token,
    };

    dispatch({
      type: SET_USER_REGISTER,
      userPayload,
    });
  };
};

export const setTokenSocial = (token) => {
  return async function (dispatch) {
    const payload = {
      token,
    };

    dispatch({
      type: SET_TOKEN_SOCIAL_LOGIN,
      payload,
    });
  };
};

export const setMessageSuccess = (viewMessage, message) => {
  return async function (dispatch) {
    const payload = {
      viewMessage,
      message,
    };

    dispatch({
      type: SET_SUCCESS_MESSAGE,
      payload,
    });
  };
};

export const getInterests = (dataPayload) => {
  return async function (dispatch) {
    const interests = await getCategories(null, dataPayload.token);

    const payload = {
      interests: interests.status ? interests.data : [],
    };

    dispatch({
      type: SET_INTERESTS_EVENTS,
      payload,
    });
  };
};

export const searchFilteredEvents = (filters, token = null) => {
  return async function (dispatch) {
    if (Object.keys(filters).length === 0) {
      console.log('No filters...');
      dispatch({
        type: SET_FILTERED_EVENTS,
        payload: null,
      });
      return;
    }

    dispatch({
      type: SET_LOADING_SCREEN,
      payload: true,
    });

    const filterList = buildFilters(filters);
    const events = await searchEventsByFilter(filterList, token);

    dispatch({
      type: SET_LOADING_SCREEN,
      payload: false,
    });

    if (!events.status) {
      dispatch({
        type: SET_FILTERED_EVENTS,
        payload: null,
      });
      return;
    }

    dispatch({
      type: SET_FILTERED_EVENTS,
      payload: events.data,
    });
  };
};

export const getUserEvents = (userId, token) => {
  return async function (dispatch) {
    dispatch({
      type: SET_LOADING_SCREEN,
      payload: true,
    });

    const response = await getEventsByUserId(userId, token);

    if (!response.status) {
      dispatch({
        type: SET_LOADING_SCREEN,
        payload: false,
      });
      return;
    }

    const events = splitEventsUser(response.data);

    dispatch({
      type: GET_USER_EVENTS,
      payload: events,
    });

    dispatch({
      type: SET_LOADING_SCREEN,
      payload: false,
    });
  };
};

export const toogleFavoriteEvent = (payload) => (dispatch, getState) => {
  const state = getState();
  const { events } = state;

  for(let categoryId of Object.keys(events)) {
    const category = events[categoryId];

    for(let event of category.events) {
      if(event.id === payload.eventId) event.is_favorited = payload.favorite;
    }
  }

  dispatch({
    type: TOGGLE_FAVORITE,
    payload: events
  })
};
