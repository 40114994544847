import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function MenuNotifications(props) {
  const {
    anchorEl,
    handleClose,
  } = props;

  return (
    <>
      <StyledMenu
        className='menu-containe-desktop'
        id='customized-menu-bash'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className='container-notification-bash'>
            <div>
              <Typography>
                <span className='text-dots-notification'>
                  Mensaje de Panamericana
                </span>
              </Typography>
              <Typography>
                <span className='text-dots-notification horario-notificacion-bash'>
                  Mayo 29 -10:00 AM
                </span>
              </Typography>
            </div>
            <div className='view-more-container'>
              <Typography>
                <span className='view-more'>
                  VER MAS
                </span>
              </Typography>
            </div>
          </div>
        </MenuItem>
      </StyledMenu>
    </>
  );
};
