import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import ItemInformation from '../components/ItemInformation';
import ROUTES from '../utils/RouteUtils';
import '../assets/styles/containers/MoreInformation.scss';
import { getCategories } from '../api/categories';

const MoreInformation = (props) => {
  const [countItemsSelected, setCountItems] = useState(0);
  const [itemsSelected, setItemsSelected] = useState(new Set());
  const [items, setItems] = useState([]);

  const handleAddItemSet = (id) => {
    const result = itemsSelected.add(id);
    setItemsSelected(result);
    setCountItems(countItemsSelected + 1);
  };

  const handleRemoveItemSet = (id) => {
    itemsSelected.delete(id);
    const result = itemsSelected;
    setItemsSelected(result);
    setCountItems(countItemsSelected - 1);
  };

  const handleSelectedItem = (id, isSelected) => {
    if (isSelected) {
      handleAddItemSet(id);
    } else {
      handleRemoveItemSet(id);
    }
  };

  const handleSubmitItemsSelected = () => {
    props.history.push(ROUTES.HOME);
  };

  const getItems = async () => {
    const response = await getCategories();

    if (!response.status) {
      console.error(response.error);
      return;
    }

    setItems([...response.data]);
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      <div className="more-information-bash">
        <Typography>
          <span className="title-more-information">
            Queremos conocerte mejor,selecciona el tipo de eventos que más te
            interesan
          </span>
        </Typography>
      </div>
      <div className="wrapper">
        {items.map((item) => (
          <ItemInformation
            key={`id_item_${item.id}`}
            id={item.id}
            title={item.name}
            handleSelectItemId={handleSelectedItem}
          />
        ))}
      </div>
      <div className="container-button-add-items">
        <Button
          type="button"
          className="btn-items-selected-bash"
          variant="contained"
          color="primary"
          disabled={countItemsSelected < 3}
          onClick={handleSubmitItemsSelected}
        >
          <Typography>Continuar</Typography>
        </Button>
      </div>
    </>
  );
};

export default MoreInformation;
