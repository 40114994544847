import React from 'react';
import {
  Button,
  Dialog,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FacebookIcon from '@material-ui/icons/Facebook';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import { Alert, Rating } from '@material-ui/lab';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setAlert, toogleFavoriteEvent } from '../../actions';
import DefaultImgEvent from '../../assets/img/img-event-default.png';
import '../../assets/styles/components/Event/DetailEvent.scss';
import {
  associateEvent,
  rateEvent,
  toggleFavorite,
} from '../../services/event.services';
import { formatCurrency } from '../../utils';
import { formatDateTime } from '../../utils/date';
import ROUTES from '../../utils/RouteUtils';
import CarouselEventDetailEvent from './CarouselEventDetailEvent';
import ShareEvent from './ShareEvent';

const EventDetail = (props) => {
  const {
    id,
    image,
    name,
    average_rating,
    start_date,
    language,
    is_favorited,
    description,
    payment_required,
    payment_description,
    items,
    links,
    owner,
    associatedEvents,
    closebutton,
    setShowLoader,
    handleCloseDetailEvent,
    previewEvent,
    url,
    toogleFavoriteEvent
  } = props;

  const user = useSelector((state) => state.user);
  const history = useHistory();

  const [rating, setRating] = useState(average_rating);
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);
  const [favorite, setFavorite] = useState(is_favorited);
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;


  const handleChangeRating = async (event, value) => {
    setShowLoader(true);
    const response = await rateEvent(id, value, user.token);
    setShowLoader(false);

    setOpenAlert(true);
    if (response.status) {
      setAlert({ severity: 'success', message: 'Rating submitted.' });
    } else {
      setAlert({ severity: 'error', message: response.message });
    }
  };

  const handleFavoriteClick = async () => {
    if (!user.token) {
      history.push(ROUTES.READY_LOGIN);
      return;
    }

    setShowLoader(true);
    const response = await toggleFavorite(id, favorite, user.token);
    setShowLoader(false);
    setOpenAlert(true);


    if (response.status) {
      setFavorite(!favorite);
      console.log(favorite);

      setAlert({ severity: 'success', message: response.message });
      toogleFavoriteEvent({eventId: id, favorite: !favorite});
    } else {
      setAlert({ severity: 'error', message: response.message });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpenAlert(false);
    setAlert(null);
  };

  const IsInBashUrl = function validarURL(url) {
    if (!urlRegex.test(url)) {
      return false;
    }

    if (url.includes("api.inbash.com") || url.includes("inbash.com")) {
      return true;
    } else {
      return false;
    }
  }

  const handleAsociateEventToUser = async () => {

    if (!user.id) {
      history.push(ROUTES.READY_LOGIN);
      return;
    }
    const urlBash = IsInBashUrl(url);
    console.log(urlBash);

    if (!urlBash) {
      window.open(url, '_blank');
      return;
    }

    setShowLoader(true);
    const response = await associateEvent(id, user.id, user.token);
    setShowLoader(false);

    setOpenAlert(true);
    if (response.status) {
      setAlert({ severity: 'success', message: 'Event associated with user.' });
      //setAlert({ severity: 'success', message: 'Event associated with user.' });
    } else {
      setAlert({ severity: 'error', message: response.message });
      //setAlert({ severity: 'error', message: response.message });
    }
  };

  const handleShowDetailEvent = () => {
    console.log('Show new event');
  };

  const setEventDetailDataLocal = (data) => {
    console.log(data);
  };

  const necesaryItems = [];
  Object.keys(items).map((i) => {
    necesaryItems.push(
      <div className="content-items-necesary" key={`Necesary_Items_${i}`}>
        {items[i]['title']}
        <div className="contetnt-item-description">
          <img src={items[i]['image']} alt="" />
          <Typography>
            <span className="description-item">{items[i]['description']}</span>
          </Typography>
          <Typography>
            <span className="price-item">{items[i]['price']}</span>
          </Typography>
          <Typography>
            <span className="url-item">{items[i]['url']}</span>
          </Typography>
        </div>
      </div>
    );
    return necesaryItems;
  });

  return (
    <>
      <div className="container-detail-event-card pb-3">
        {closebutton && (
          <>
            <div className="back-btn-phones">
              <ArrowBackIcon onClick={handleCloseDetailEvent} />
            </div>
            <div className="container-close-btn">
              <HighlightOffIcon
                onClick={handleCloseDetailEvent}
                type="button"
              />
            </div>
          </>
        )}
        <div className="container-img-detail-event-card">
          <img src={image || DefaultImgEvent} alt="event-image" />
        </div>
        <Paper className="container-info-detail-event">
          <div className="container-basic-info-event">
            <Grid container component="main" className="">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                className="container-secction-info-detail-event"
              >
                <Typography>
                  <span className="title-detail-event">{name}</span>
                </Typography>
              </Grid>
              {!previewEvent && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  xl={6}
                  className="container-secction-info-detail-event"
                >
                  <div className="qualification-event-detail">
                    {rating}
                    <Rating
                      name="rating"
                      value={rating}
                      precision={0.5}
                      onChange={handleChangeRating}
                      readOnly={!user.id || user.id === owner.uuid}
                    />
                    <Typography>
                      <span className="qualification-comments-desktop">
                        Ver todos los comentarios (50)
                      </span>
                      <span className="qualification-comments-mobile">
                        (50)
                      </span>
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container component="main" className="">
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">¿Cuándo?</span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {formatDateTime(new Date(start_date))}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">
                  ¿Quién lo organiza?
                </span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {`${owner.name} - ${owner.email}`}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">Idioma</span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {language}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">Precio</span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {payment_required
                    ? `${payment_description.currency} $${formatCurrency(
                      payment_description.amount,
                      payment_description.currency
                    )}`
                    : 'Free'}
                </span>
              </Typography>
              {user && user.id !== owner.uuid && (
                <Button
                  type="button"
                  className="btn-register-event"
                  variant="contained"
                  color="secondary"
                  onClick={handleAsociateEventToUser}
                >
                  REGISTRO
                </Button>
              )}
            </Grid>
          </Grid>
          <section className="actions-container">
            <div
              className="rounded-icon"
              onClick={() => {
                setShareOpen(true);
              }}
            >
              <ShareIcon className="icon" />
            </div>
            <div
              className="rounded-icon favorite"
              onClick={() => handleFavoriteClick()}
            >
              {!favorite && <FavoriteBorderIcon className="icon" />}
              {favorite && <FavoriteIcon className="icon fill" />}
            </div>
          </section>
        </Paper>
        <div className="container-detail-event">
          <div>
            <Typography>
              <span className="title-event-detail-event">
                Acerca del evento
              </span>
            </Typography>
          </div>
          <div className="description-container-event-detail">
            <Typography>
              <span className="description-event-detail-event">
                {description}
              </span>
            </Typography>
          </div>
        </div>
        <div className="container-secction container-detail-event">
          {!previewEvent && necesaryItems.length > 0 && (
            <>
              <div>
                <Typography>
                  <span className="title-event-detail-event">
                    ¿Qué necesitas?
                  </span>
                </Typography>
              </div>
              <div className="grid-necesary-items description-container-event-detail">
                {necesaryItems}
              </div>
            </>
          )}
        </div>
        {links && (
          <div className="container-secction container-detail-event">
            <div>
              <Typography>
                <span className="title-event-detail-event">
                  Comparte con tus amigos
                </span>
              </Typography>
              <div className="container-social-icons">
                <a href={links.twitter} target="_blank">
                  <TwitterIcon />
                </a>
                <a href={links.facebook} target="_blank">
                  <FacebookIcon />
                </a>
                <a href={links.instagram} target="_blank">
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
        )}
        {associatedEvents.length > 0 && (
          <div className="container-secction container-detail-event">
            {!previewEvent && (
              <div className="container-title-another-events">
                <Typography>
                  <span className="title-event-detail-event">
                    Otros eventos que te pueden interesar
                  </span>
                </Typography>
              </div>
            )}
            <CarouselEventDetailEvent
              events={associatedEvents}
              setShowLoader={setShowLoader}
              handleShowDetailEventPage={handleShowDetailEvent}
              setEventDetailData={setEventDetailDataLocal}
            />
          </div>
        )}
      </div>
      <Dialog open={shareOpen} onClose={() => setShareOpen(!shareOpen)} >
        <ShareEvent id={id} />
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert ? alert.severity : 'success'}
        >
          {alert ? alert.message : ''}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapDispatchToProps = {
  setAlert,
  toogleFavoriteEvent
};

export default connect(null, mapDispatchToProps)(EventDetail);
