import React, { useState } from 'react';
import { connect } from 'react-redux';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { changePassword } from '../../services/user.services';
import { setMessageSuccess, setAlert } from '../../actions';

const ChangePassword = (props) => {
  const { user, handleClose, setLoading, setMessageSuccess, setAlert } = props;

  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    old_password: '',
    new_password: '',
    new_password_confirm: ''
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);
  const handleClickShowNewPasswordConfirm = () =>
    setShowNewPassword(!showNewPasswordConfirm);
  const handleMouseDownNewPasswordConfirm = () =>
    setShowNewPasswordConfirm(!showNewPasswordConfirm);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const submitFormChangePassword = (event) => {
    setLoading(true);
    event.preventDefault();
    console.log('On submit change password');

    changePassword(form, user.email, user.token)
      .then((response) => {
        console.log(response);

        if (!response.status) {
          setAlert({ severity: 'error', message: response.message });

          setError(true);
          return;
        }

        setMessageSuccess(true, response.message);
        handleClose();
        setError(false);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setAlert({ severity: 'error', message: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mask-change-password">
      <div className="card-change-password">
        <div className="container-form-change-password">
          <div className="back-btn-phones">
            <ArrowBackIcon onClick={handleClose} />
          </div>
          <div className="container-close-btn">
            <HighlightOffIcon onClick={handleClose} />
          </div>
          <Typography className="container-title-text">
            <span className="title-text">Cambio de contraseña</span>
          </Typography>

          <form onSubmit={submitFormChangePassword}>
            <TextField
              onChange={handleChange}
              error={error}
              color="primary"
              className="input-login input-change-password"
              id="old_password"
              label="Contraseña actual"
              name="old_password"
              type={showPassword ? 'text' : 'password'}
              required
              helperText={error ? 'Error en los datos' : ''}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              onChange={handleChange}
              color="primary"
              className="input-login input-change-password"
              id="new_password"
              label="Nueva contraseña"
              name="new_password"
              type={showNewPassword ? 'text' : 'password'}
              required
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              onChange={handleChange}
              color="primary"
              className="input-login input-change-password"
              id="new_password_confirm"
              label="Confirmar nueva contraseña"
              name="new_password_confirm"
              type={showNewPasswordConfirm ? 'text' : 'password'}
              required
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPasswordConfirm}
                      onMouseDown={handleMouseDownNewPasswordConfirm}
                    >
                      {showNewPasswordConfirm ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              className="btn-login-bash"
              variant="contained"
              color="primary"
            >
              GUARDAR
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setMessageSuccess,
  setAlert,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
