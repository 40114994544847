import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import UnderlineTitle from '../../assets/img/underline-title-secction.svg';
import '../../assets/styles/components/Event/CarouselEvent.scss';
import { RESPONSIVE_CAROUSEL_EVENTS } from '../../utils/CarouselConst';
import CardEvent from './CardEvent';
import { CustomLeftArrow, CustomRightArrow } from './CustomButtons';

const CarouselEvent = (props) => {
  const responsive = RESPONSIVE_CAROUSEL_EVENTS;
  const {
    title,
    withOutTitle,
    events,
    pendingPublish,
    setShowLoader,
    handleShowDetailEventPage,
    setEventDetailData,
  } = props;
  const handleShowDetailEvent = (id, event) => {
    setShowLoader(true);
    console.log(event);
    setEventDetailData(event);
    handleShowDetailEventPage();
    setShowLoader(false);
  };

  return (
    <>
      <div>
        {!withOutTitle && (
          <div className="title-section-event">
            <span>{title}</span>
            <img src={UnderlineTitle} alt="" />
          </div>
        )}
        <div className="content">
          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={false}
            infinite={false}
            keyBoardControl={true}
            transitionDuration={300}
            arrows={true}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[
              'mobileScreen1',
              'mobileScreen2',
              'mobileScreen3',
              'mobileScreen4',
              'mobileScreen5',
              'mobileScreen6',
              'mobileScreen7',
              'mobileScreen8',
              'mobileScreen9',
              'mobileScreen10',
              'mobileScreen11',
              'mobileScreen12',
              'mobileScreen13',
            ]}
            dotListClass="custom-dot-list-style"
            focusOnSelect={false}
            itemClass="item-carrousel"
          >
            {events.map((event) => (
              <CardEvent
                key={`item_event_${events['id']}`}
                id={events['id']}
                event={event}
                img={event['image']}
                location={event['location']}
                title={event['name']}
                date={event['start_date']}
                lenguaje={event['language']}
                price={event['price']}
                qualification={event['qualification']}
                pendingPublish={pendingPublish}
                handleShowDetailEvent={handleShowDetailEvent}
              />
            ))}
            {events.length === 0 && (
              <p>No events for this category :(</p>
            )}
          </Carousel>
        </div>
      </div>
    </>
  );
};

CarouselEvent.propTypes = {
  title: PropTypes.string,
  events: PropTypes.any,
  handleShowDetailEvent: PropTypes.any,
};

export default CarouselEvent;
