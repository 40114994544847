import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getEventById } from '../../services/event.services';
import Loading from '../Loading';
import EventDetail from './EventDetail';

const EventDetailsScreen = () => {
  const params = useParams();
  const history = useHistory();

  const { id } = params;
  const user = useSelector((state) => state.user);

  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getEvent = async () => {
    const response = await getEventById(id, user.token);
    setLoading(false);

    if (!response.status) {
      setError(response.error);
      return;
    }

    setEvent(response.data);
  };

  const setShowLoader = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    setLoading(true);
    getEvent();
  }, [id]);

  return (
    <>
      {!error && event && (
        <EventDetail {...event} items={[]} associatedEvents={[]} setShowLoader={setShowLoader} />
      )}
      {error && (
        <div className="content">
          <h1>{error}</h1>
        </div>
      )}
      {loading && (
        <div className="container-loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default EventDetailsScreen;
