import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  addEventsCategories,
  getEventsCategories,
  getBanners,
  setUser
} from '../actions/index';
import CarouselEvent from '../components/Event/CarouselEvent';
import EventDetail from '../components/Event/EventDetail';
import FilterHome from '../components/Home/FilterHome';
import Loading from '../components/Loading';
import { getOptionsInterests } from '../services/menu-services';
import NothingEventSVG from '../assets/img/nothing_event.svg';
import UnderlineTitle from '../assets/img/underline-title-secction.svg';

import '../assets/styles/components/Event/CarouselEvent.scss';
import '../assets/styles/containers/Home.scss';
import { getProfile } from '../services/user.services';

const Home = (props) => {
  const {
    events,
    totalCategories,
    filteredEvents,
    loadingScreen,
    addEventsCategories,
    getBanners,
    setUser,
    user,
  } = props;

  const [showDetailEvent, setShowDetailEvent] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [eventDetailData, setEventDetailData] = useState({});
  const [categoriesDisplayed, setCategoriesDisplayed] = useState(3);

  const setUserInformation = async () => {
    if(!user.token) return;

    const response = await getProfile(user.token);
    if (response.status) {
      setUser({
        id: response.id,
        name: response.name,
        email: response.email,
        image: response.image,
      });
    }
  };


  useEffect(() => {
    getOptionsInterests();
    addEventsCategories({
      count: categoriesDisplayed,
      token: user.token ?? null,
    });
    getBanners();
    setUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowLoader(loadingScreen);
  }, [loadingScreen]);

  const handleShowDetailEvent = () => {
    setShowDetailEvent(true);
  };

  const handleCloseDetailEvent = () => {
    setShowDetailEvent(false);
  };

  const handleAddEventsCategories = () => {
    console.log('Add Events Categories');
    const displayed = Math.min(categoriesDisplayed + 3, totalCategories);
    setCategoriesDisplayed(displayed);

    addEventsCategories({ count: displayed, token: user.token });
  };

  return (
    <>
      <FilterHome setShowLoader={setShowLoader} />
      {filteredEvents && filteredEvents.length > 0 && (
        <CarouselEvent
          title="Results"
          events={filteredEvents}
          setShowLoader={setShowLoader}
          handleShowDetailEventPage={handleShowDetailEvent}
          setEventDetailData={setEventDetailData}
        />
      )}
      {filteredEvents && filteredEvents.length === 0 && (
        <Grid container xs={12} justify="center">
          <div className="title-section-event">
            <span>Results</span>
            <img src={UnderlineTitle} alt="" />
          </div>
          <section className="nothin-event">
            <Typography>
              No se ha encontrado ningún evento para los filtros seleccionados
            </Typography>
            <img src={NothingEventSVG} alt="" />
          </section>
        </Grid>
      )}
      {Object.keys(events).map((categoryId) => {
        const category = events[categoryId]['category'];
        const list = events[categoryId]['events'];
        return (
          <CarouselEvent
            key={`CarouselEvent_Home_${categoryId}`}
            title={category?.name || ''}
            events={list}
            setShowLoader={setShowLoader}
            handleShowDetailEventPage={handleShowDetailEvent}
            setEventDetailData={setEventDetailData}
          />
        );
      })}
      {showLoader && (
        <div className="container-loader-carousel">
          <Loading />
        </div>
      )}
      {showDetailEvent && (
        <div className="curtain-detail-event">
          <EventDetail
            {...eventDetailData}
            items={[]}
            associatedEvents={[]}
            closebutton={true}
            setShowLoader={setShowLoader}
            handleShowDetailEventPage={handleShowDetailEvent}
            handleCloseDetailEvent={handleCloseDetailEvent}
            setEventDetailData={setEventDetailData}
            previewEvent={false}
          />
        </div>
      )}
      {categoriesDisplayed < totalCategories && (
        <div className="btn-more-events-bash">
          <Button
            className="btn btn-success"
            onClick={handleAddEventsCategories}
          >
            <Typography className="text-view-more">VER MÁS</Typography>
          </Button>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  getEventsCategories,
  addEventsCategories,
  getBanners,
  getProfile,
  setUser
};

const mapStateToProps = (state) => {
  return {
    events: state.events,
    user: state.user,
    totalCategories: state.total_categories,
    filteredEvents: state.filtered_events,
    loadingScreen: state.loading_screen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
