import React, { useState } from 'react';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Badge, Typography } from '@material-ui/core';
import MenuNotifications from './MenuNotifications';

const Notifications = () => {

  const numNotifications = 1;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div role='presentation' className='notifications-bash-container' onClick={handleClick}>
        <div className='icon-notifications-bash'>
          <Badge color='primary' badgeContent={numNotifications} showZero>
            <NotificationsNoneIcon />
          </Badge>
        </div>
        <span>
          <Typography>
            Notificaciones
          </Typography>
        </span>
      </div>
      <MenuNotifications
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
      />
    </>
  );
};

export default Notifications;
