import {
  Chip,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import FilterModal from './FilterModal';

import { searchFilteredEvents } from '../../actions';
import { formatDate } from '../../utils/date';
import { getInterestsSelected } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '21px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const FilterEventsComponent = (props) => {
  const { user, interests, searchFilteredEvents } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState(false);

  const [form, setForm] = useState({
    name: '',
    interests: {},
    startDate: new Date(),
    endDate: new Date(),
    minPrice: 0,
    maxPrice: 800,
    lenguaje: '',
    filterStartDate: false,
    filterEndDate: false,
    filterMinPrice: false,
    filterMaxPrice: false,
    filterLanguage: false,
  });

  const handleChange = (event, search = false) => {
    const { name, value } = event.target;

    let filters = {};
    if (name === 'startDate') {
      filters = { ...filters, filterStartDate: true };
    } else if (name === 'endDate') {
      filters = { ...filters, filterEndDate: true };
    } else if (name === 'minPrice') {
      filters = { ...filters, filterMinPrice: true };
    } else if (name === 'maxPrice') {
      filters = { ...filters, filterMaxPrice: true };
    } else if (name === 'lenguaje') {
      filters = { ...filters, filterLanguage: true };
    }

    setForm({
      ...form,
      [name]: value,
      ...filters,
    });

    setSearch(search);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter(form.interests);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const onFilter = (interestsSelected) => {
    handleChange({
      target: {
        name: 'interests',
        value: interestsSelected,
      },
    });

    const filters = getFilters(interestsSelected);
    searchFilteredEvents(filters, user.token);

    handleCloseFilter();
  };

  const handleDeleteInterest = (key) => {
    handleChange(
      {
        target: {
          name: 'interests',
          value: { ...form.interests, [key]: false },
        },
      },
      true
    );
  };

  const getDateToFormat = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return date ? date.toLocaleDateString(undefined, options) : null;
  };

  const getInterestLabelById = (id) => {
    const item = interests.find((interest) => interest.id == id);
    return item ? item.name : '';
  };

  const getFilters = (interestsSelected) => {
    const filters = { state: 'published' };

    if (form.filterStartDate) {
      filters['startDate'] = formatDate(form.startDate, true);
    }

    if (form.filterEndDate) {
      filters['endDate'] = formatDate(form.endDate, true);
    }

    if (form.filterMinPrice) {
      filters['minPrice'] = form.minPrice;
    }

    if (form.filterMaxPrice) {
      filters['maxPrice'] = form.maxPrice;
    }

    if (form.filterLanguage) {
      filters['lenguaje'] = form.lenguaje;
    }

    const selected = getInterestsSelected(interestsSelected);
    if (selected.length > 0) {
      filters['interests'] = selected;
    }

    if (form.name) {
      filters['name'] = `%${form.name}%`;
    }

    return filters;
  };

  useEffect(() => {
    if (!interests) return;

    const interestSelected = {};
    interests.map((interest) => (interestSelected[interest.id] = false));
    handleChange({ target: { name: 'interests', value: interestSelected } });
  }, [interests]);

  useEffect(() => {
    if (search) {
      const filters = getFilters(form.interests);
      searchFilteredEvents(filters, user.token);
      setSearch(false);
    }
  }, [form]);

  return (
    <>
      <section>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.root}>
            <IconButton
              className={classes.iconButton}
              aria-label="menu"
              onClick={handleOpenFilter}
            >
              <TuneIcon />
            </IconButton>
            <InputBase
              name="name"
              className={classes.input}
              placeholder="¿Qué quieres hacer hoy?"
              onChange={handleChange}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </form>
        <section className="mt-1 scroll-horizontal-filters">
          {Object.entries(form.interests)
            .filter((item) => item[1])
            .map((item, index) => {
              return (
                <Chip
                  key={`filter_interest_${item[0]}`}
                  color="secondary"
                  style={{
                    margin: '1px',
                  }}
                  label={getInterestLabelById(item[0])}
                  onDelete={() => handleDeleteInterest(item[0])}
                />
              );
            })}

          {form.filterStartDate && (
            <Chip
              key="start_date"
              color="secondary"
              style={{
                margin: '1px',
              }}
              label={`Start Date: ${getDateToFormat(form.startDate)}`}
              onDelete={() => setForm({ ...form, filterStartDate: false })}
            />
          )}
          {form.filterEndDate && (
            <Chip
              key="end_date"
              color="secondary"
              style={{
                margin: '1px',
              }}
              label={`End Date: ${getDateToFormat(form.endDate)}`}
              onDelete={() => setForm({ ...form, filterEndDate: false })}
            />
          )}
          {form.filterLanguage && (
            <Chip
              key="language"
              color="secondary"
              style={{
                margin: '1px',
              }}
              label={`Language: ${form.lenguaje}`}
              onDelete={() => setForm({ ...form, filterLanguage: false })}
            />
          )}
          {form.filterMinPrice && (
            <Chip
              key="min_price"
              color="secondary"
              style={{
                margin: '1px',
              }}
              label={`Min Price: ${form.minPrice}`}
              onDelete={() => setForm({ ...form, filterMinPrice: false })}
            />
          )}
          {form.filterMaxPrice && (
            <Chip
              key="max_price"
              color="secondary"
              style={{
                margin: '1px',
              }}
              label={`Max Price: ${form.maxPrice}`}
              onDelete={() => setForm({ ...form, filterMaxPrice: false })}
            />
          )}
        </section>
      </section>
      <FilterModal
        open={openFilter}
        handleClose={handleCloseFilter}
        interests={interests}
        activeSections={form.interests}
        onFilter={onFilter}
        form={form}
        handleChange={handleChange}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};

const mapDispatchToProps = {
  searchFilteredEvents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterEventsComponent);
