const SET_USER = 'SET_USER';
const USER_LOGOUT = 'USER_LOGOUT';
const GET_EVENTS = 'GET_EVENTS';
const ADD_EVENTS = 'ADD_EVENTS';
const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
const SET_EMAIL = 'SET_EMAIL';
const GET_CATEGORIES = 'GET_CATEGORIES';
const SET_USER_REGISTER = 'SET_USER_REGISTER';
const SET_TOKEN_SOCIAL_LOGIN = 'SET_TOKEN_SOCIAL_LOGIN';
const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
const SET_INTERESTS_EVENTS = 'SET_INTERESTS_EVENTS';
const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
const SET_FILTERED_EVENTS = 'SET_FILTERED_EVENTS';
const SET_LOADING_SCREEN = 'SET_LOADING_SCREEN';
const GET_BANNER_IMAGES = 'GET_BANNER_IMAGES';
const GET_USER_EVENTS = 'GET_USER_EVENTS';
const SET_ALERT = 'SET_ALERT';
const TOGGLE_FAVORITE = 'TOGGLE_FAVORTIE';

export {
  SET_USER,
  USER_LOGOUT,
  GET_EVENTS,
  ADD_EVENTS,
  SOCIAL_LOGIN,
  SET_EMAIL,
  GET_CATEGORIES,
  SET_USER_REGISTER,
  SET_TOKEN_SOCIAL_LOGIN,
  SET_SUCCESS_MESSAGE,
  SET_INTERESTS_EVENTS,
  GET_EVENTS_ERROR,
  SET_FILTERED_EVENTS,
  SET_LOADING_SCREEN,
  GET_BANNER_IMAGES,
  GET_USER_EVENTS,
  SET_ALERT,
  TOGGLE_FAVORITE
};
