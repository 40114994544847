import { Button, Grid, TextField, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setMessageSuccess, setUser } from '../../actions/index';
import '../../assets/styles/components/User/Profile.scss';
import { getPreferences, getProfile, setProfile } from '../../services/user.services';
import SelectCategories from '../Event/CreateEventSteps/SelectCategorites';
import Loading from '../Loading';
import ChangePassword from './ChangePassword';
import DropZone from './DropZone';
import { getCategories } from '../../api/categories'

const Profile = (props) => {

  const {
    user,
    setUser,
    setMessageSuccess,
  } = props;

  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const [selectPreferences, setSelectPreferences] = useState({});
  const [showSelectPreferences, setShowSelectPreferences] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [imgProfile, setImgProfile] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    preferences: {},
  });

  const handleShowSelectPreferences = () => setShowSelectPreferences(true);
  const handleCloseSelectPreferences = () => setShowSelectPreferences(false);

  const handleSelectPreference = (id) => {
    setSelectPreferences({ ...selectPreferences, [id]: !selectPreferences[id] });
    setForm({
      ...form,
      preferences: { ...selectPreferences, [id]: !selectPreferences[id] },
    });
  };

  const handleSetImage = (imgB64, file) => {
    setImgProfile(imgB64);
    setImgFile(file);
  };

  const handleShowChangePassword = () => {
    setChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setChangePassword(false);
  };

  useEffect(() => {

    const chargeProfile = async () => {

      setLoading(true);
      const profile = await getProfile(user.token);

      if (profile.status) {
        setForm({
          name: profile.name,
          email: profile.email,
          password: profile.password,
          preferences: {},
        });
        setImgProfile(profile.image);
      }

      if (user.image) {
        setImgProfile(user.image);
      }


      getCategories()
        .then((success) => {
          const preferencesSelected = {};
          success.data.map((category) => {
            preferencesSelected[category.id] = false;
          });
          setSelectPreferences(preferencesSelected);
          setPreferences(success.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });

    };
    chargeProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const submitForm = (event) => {
    console.log('Submit form save user profile');
    event.preventDefault();
    setLoading(true);
    const data = {
      ...form,
      image: imgFile,
    };

    setProfile(data, user.token)
      .then((success) => {
        setUser(success.data.data);
        setImgProfile(success.data.data.image);
        setMessageSuccess(true, 'Se ha guardado correctamente su perfil.');
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {changePassword && (
        <ChangePassword
          handleClose={handleCloseChangePassword}
          setLoading={setLoading}
        />
      )}
      {loading && (
        <div className='container-loader-carousel'>
          <Loading />
        </div>
      )}
      <form onSubmit={submitForm} className='container-form-profile'>
        <Grid container component='main' className=''>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div className='container-input-profile'>
              <TextField
                onChange={handleChange}
                color='primary'
                className='input-form-profile'
                id='name'
                label='Nombres y Apellidos'
                name='name'
                value={form.name}
                required
              />
            </div>
            <div className='container-input-profile'>
              <TextField
                onChange={handleChange}
                color='primary'
                className='input-form-profile'
                id='email'
                label='Correo'
                name='email'
                value={form.email}
                disabled
              />
            </div>
            <div className='container-input-profile'>
              <TextField
                onChange={handleChange}
                color='primary'
                className='input-form-profile'
                id='password'
                label='Contraseña'
                name='password'
                value={form.password}
                disabled
              />
              <div className='container-text-link-profile-bash'>
                <Typography>
                  <span onClick={handleShowChangePassword}>
                    Cambiar contraseña
                  </span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <DropZone
              img={imgProfile}
              setImage={handleSetImage}
              setLoading={setLoading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <div className='container-interest-list'>
              <Typography>
                <span className='title-interest-list'>
                  Mis Intereses
                </span>
              </Typography>
              <div className='container-list'>
                {
                  preferences.map((preference) => {
                    if (selectPreferences[preference.id]) {
                      return (
                        <div className='item-list' key={`Preference_User_${preference.id}`}>
                          <Typography>
                            <span className='title-interest'>
                              {preference.name}
                            </span>
                          </Typography>
                          <HighlightOffIcon fontSize='small' onClick={() => handleSelectPreference(preference.id)} />
                        </div>
                      );
                    }
                    return null;
                  })
                }
              </div>
              <div className='container-text-link'>
                <Typography>
                  <span className='' onClick={handleShowSelectPreferences}>
                    Cambiar intreses
                  </span>
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container component='main' className=''>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <div className='container-save-profile-btn'>
              <Button type='submit' className='btn-login-bash' variant='contained' color='primary'>GUARDAR</Button>
            </div>
          </Grid>
        </Grid>
      </form>
      {showSelectPreferences && (
        <SelectCategories
          show={showSelectPreferences}
          closeSelectCategories={handleCloseSelectPreferences}
          handleSelectCategory={handleSelectPreference}
          categories={preferences}
          selectedCategories={selectPreferences}
          isPreference={true}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setUser,
  setMessageSuccess,
};

const mapPropsToDispatch = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapPropsToDispatch, mapDispatchToProps)(Profile);
