import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import { Typography } from '@material-ui/core';
import ROUTES from '../../utils/RouteUtils';
import NewEventButton from './NewEventButton';
import { logoutUser } from '../../actions/index';
import MenuIcon from '../../assets/img/icon-menu-mobile.png';
import { logOut } from '../../services/authentication-services';

const MenuMobile = (props) => {

  const { user, logoutUser } = props;
  const [visibleMenuMobile, setVisibleMenuMobile] = useState(false);

  const handleVisibleMobileMenu = () => {
    setVisibleMenuMobile(!visibleMenuMobile);
  };

  const handleCloseMenuMobile = () => {
    setVisibleMenuMobile(false);
  };

  const logoutUserMenu = () => {
    console.log('LogOut User mobile');
    logOut(user.token);
    logoutUser();
    handleCloseMenuMobile();
    props.history.push(ROUTES.HOME);
  };

  return (
    <>
      <div className='overlay' style={visibleMenuMobile ? { 'height': '100vh' } : { 'height': '0%' }}>
        <div className='container-header'>
          <div className='header'>
            <Link className='logo' to={ROUTES.HOME} onClick={handleVisibleMobileMenu}>
              <Typography>Bash</Typography>
            </Link>
            <div className='close-btn-menu-mobile'>
              <CancelIcon onClick={handleVisibleMobileMenu} />
            </div>
          </div>
        </div>
        <div className='overlay-content'>
          <div className='container-menu-mobile formating-menu-mobile'>
            <Link to={ROUTES.HOME} className='menu-mobile-text text-center' onClick={handleCloseMenuMobile}>
              <div className='menu-mobile-text text-center'>
                <Typography>Inicio</Typography>
              </div>
            </Link>
          </div>
          {user.token && (
            <div className='container-menu-mobile formating-menu-mobile'>
              <Link to={ROUTES.FAVORITES} onClick={handleCloseMenuMobile}>
                <div className='menu-mobile-text text-center'>
                  <Typography>Favoritos</Typography>
                </div>
              </Link>
            </div>
          )}
          {user.token && (
            <div className='container-menu-mobile formating-menu-mobile'>
              <div className='menu-mobile-text text-center'>
                <Typography>Notificaciones</Typography>
              </div>
            </div>
          )}
          {!user.token && (
            <div className='container-menu-mobile formating-menu-mobile'>
              <Link to={ROUTES.READY_LOGIN} className='menu-mobile-text text-center' onClick={handleCloseMenuMobile}>
                <Typography>Iniciar Sesión</Typography>
              </Link>
            </div>
          )}
          <div className='formating-menu-mobile'>
            <NewEventButton />
          </div>
          {user.token && (
            <div className='container-menu-mobile formating-menu-mobile'>
              <div className='menu-mobile-text text-center'>
                <Link to={ROUTES.PROFILE} className='menu-mobile-text text-center' onClick={handleCloseMenuMobile}>
                  <Typography>Perfil</Typography>
                </Link>
              </div>
            </div>
          )}
          {user.token && (
            <div className='container-menu-mobile formating-menu-mobile'>
              <div onClick={logoutUserMenu} className='menu-mobile-text text-center'>
                <Typography>Cerrar Sesión</Typography>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='icon-menu-mobile'>
        <img src={MenuIcon} onClick={handleVisibleMobileMenu} alt='' />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  logoutUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuMobile));
