import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import 'fontsource-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/configureStore';
import App from './routes/App';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#a34aff',
      main: '#6805f2',
      dark: '#8245BF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#63ffce',
      main: '#02d99d',
      dark: '#00a66e',
      contrastText: '#FFFFFF',
    },
  },
});

theme.typography.h3 = {};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
