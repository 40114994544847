const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/notfound',
  READY_LOGIN: '/authentication/ready',
  LOGIN: '/authentication/login',
  REGISTER: '/authentication/register',
  RESET_PASSWORD: '/authentication/reset-password',
  RESET_PASSWORD_MAIL: '/reset-password',
  MORE_INFORMATION: '/more/information',
  FAVORITES: '/favorites',
  PROFILE: '/user/profile',
  MY_EVENTS: '/user/events',
  NEW_EVENT: '/create/event',
  STATICS_EVENT: '/statics/event/:id',
  EDIT_EVENT: '/edit/event/:id',
  DETAILS_EVENT: '/details/event/:id',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ABOUT_US: '/about-us',
};

export default ROUTES;
