import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import DefaultImgEvent from '../../assets/img/untitled.svg';
import '../../assets/styles/components/Event/CardEvent.scss';
import { changeEventStatus } from '../../services/event.services';
import { EVENT_STATE, EVENT_TYPE } from '../../utils';
import { formatDateTime } from '../../utils/date';
import ROUTES from '../../utils/RouteUtils';

const CardEvent = (props) => {
  const {
    handleShowDetailEvent,
    id,
    img,
    location,
    title,
    date,
    lenguaje,
    price,
    qualification,
    pendingPublish,
    event,
    isMyEvent,
    handleCancelEvent,
    handlePublishEvent,
    handleShowDeleteEvent,
  } = props;

  const user = useSelector((state) => state.user);

  const history = useHistory();

  const handleRedirectEdit = (idEvent) => {
    history.push(ROUTES.EDIT_EVENT.replace(':id', idEvent));
  };

  const handleShowDetail = () => {
    handleShowDetailEvent(id, event);
  };

  const handlePublish = () => {
    handlePublishEvent(id, event);
  };

  const handleCancel = () => {
    handleCancelEvent(id, event);
  };

  const handleRedirectStatics = (idEvent) => {
    history.push(ROUTES.STATICS_EVENT.replace(':id', idEvent));
  };

  let locationLabel = 'No especified';

  if (location) {
    switch (location.type) {
      case EVENT_TYPE.in_person:
        locationLabel = location.address;
        break;
      case EVENT_TYPE.online:
        locationLabel = 'Online';
        break;
      default:
        locationLabel = 'To be defined';
        break;
    }
  }

  return (
    <>
      <div className="container-img-event-card">
        {isMyEvent && (
          <div className="container-actions-card">
            {event.state !== EVENT_STATE.cancelled && (
              <EditIcon
                onClick={() => {
                  handleRedirectEdit(id);
                }}
              />
            )}
            {event.state === EVENT_STATE.draft && (
              <DeleteIcon onClick={() => handleShowDeleteEvent(id)} />
            )}
            {event.state === EVENT_STATE.published && (
              <EqualizerIcon onClick={() => handleRedirectStatics(id)} />
            )}
          </div>
        )}
        <img
          type="button"
          className="img-card-event"
          src={img != null ? img : DefaultImgEvent}
          alt=""
          onClick={handleShowDetail}
        />
      </div>
      <Card className="card-new-event-carousel" elevation={3}>
        <CardContent onClick={handleShowDetail}>
          <div className="row-card container-location-card">
            <div className="icon-marked-bash">
              <LocationOnIcon />
            </div>
            <div className="text-margins-location without-paddings">
              {locationLabel}
            </div>
          </div>

          <div className="row-card">
            <div className="without-paddings text-nowrap">
              <Typography className="text-dots text-card-event-bash">
                {title}
              </Typography>
            </div>
          </div>
          <div className="row-card">
            <div className="without-paddings text-nowrap">
              <Typography className="text-dots text-low-card-event-bash">
                {formatDateTime(new Date(date))}
              </Typography>
            </div>
          </div>
          <div className="row-card">
            <div className="without-paddings text-nowrap">
              <Typography className="text-dots text-low-card-event-bash">
                {lenguaje}
              </Typography>
            </div>
          </div>
          <div className="row-card">
            <div className="without-paddings text-nowrap">
              <Typography className="text-dots text-low-card-event-bash">
                {price}
              </Typography>
            </div>
          </div>
          {!pendingPublish && (
            <div className="row-card">
              <div className="container-qualification without-paddings text-nowrap">
                <Typography className="text-low-card-event-bash">
                  {qualification}
                </Typography>
                <StarBorderRoundedIcon />
                <Typography className="text-low-card-event-bash">
                  (5,0)
                </Typography>
              </div>
            </div>
          )}
        </CardContent>
        <CardActions>
          {isMyEvent && event.state === EVENT_STATE.draft && (
            <Button
              type="button"
              className="btn-publish"
              variant="contained"
              color="primary"
              onClick={handlePublish}
            >
              PUBLICAR
            </Button>
          )}
          {isMyEvent && event.state === EVENT_STATE.published && (
            <Button
              type="button"
              className="btn-publish"
              variant="contained"
              color="default"
              onClick={handleCancel}
            >
              CANCELAR
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};
CardEvent.propTypes = {
  handleShowDetailEvent: PropTypes.any,
  id: PropTypes.string,
  img: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  date: PropTypes.string,
  lenguaje: PropTypes.string,
  price: PropTypes.string,
  qualification: PropTypes.string,
};

export default CardEvent;
