import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getInterests } from '../../actions/index';
import '../../assets/styles/components/SearchEvent/SearchEvents.scss';
import FilterEventsComponent from '../CustomBashComponents/FilterEventsComponent';

const SearchEvents = (props) => {
  const { user, getInterests, interests, handleSearchEvents } = props;

  useEffect(() => {
    if (!interests) {
      getInterests({ token: user.token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="container-form search-group-header">
        <div className="container-input-group">
          <FilterEventsComponent
            handleFilter={handleSearchEvents}
            interests={interests}
          />
        </div>
      </section>
    </>
  );
};

SearchEvents.propTypes = {
  handleSearchEvents: PropTypes.func,
};

const mapDispatchToProps = {
  getInterests,
};

const mapPropsToDispatch = (state) => {
  return {
    user: state.user,
    interests: state.interests,
  };
};

export default connect(mapPropsToDispatch, mapDispatchToProps)(SearchEvents);
