import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { setAlert } from '../actions';
import Home from '../containers/Home';
import Layout from '../containers/Layout';
import Authentication from '../containers/Authentication';
import NotFount from '../containers/NotFound';
import ROUTES from '../utils/RouteUtils';
import '../assets/styles/App.scss';
import ReadyLogin from '../components/Authentication/ReadyLogin';
import Login from '../components/Authentication/Login';
import Register from '../components/Authentication/Register';
import MoreInformation from '../containers/MoreInformation';
import Favorites from '../components/User/Favorites';
import Profile from '../components/User/Profile';
import User from '../containers/User';
import MyEvents from '../components/User/MyEvents';
import CreateNewEvent from '../components/Event/CreateNewEvent';
import StaticsEvent from '../components/Event/StaticsEvent';
import ScrollToTop from '../containers/ScrollToTop';
import AuthUser from '../components/Authentication/AuthUser';
import EditEvent from '../components/Event/EditEvent';
import ResetPassword from '../components/Authentication/ResetPassword';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import EventDetailsScreen from '../components/Event/EventDetailsScreen';
import ResetPasswordMail from '../components/Authentication/ResetPasswordMail';
import TermsAndConditions from '../components/Pages/TermsAndConditions';
import AboutUs from '../components/Pages/AboutUs';

const App = (props) => {
  const { setAlert } = props;
  const alert = useSelector((state) => state.alert);
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpenAlert(false);
    setAlert(null);
  };

  useEffect(() => {
    console.log(alert);
    if (alert) setOpenAlert(true);
    else setOpenAlert(false);
  }, [alert]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/authentication/:path?" exact>
          <Authentication>
            <Switch>
              <Route path={ROUTES.READY_LOGIN} component={ReadyLogin} />
              <Route path={ROUTES.LOGIN} component={Login} />
              <Route path={ROUTES.REGISTER} component={Register} />
              <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
            </Switch>
          </Authentication>
        </Route>
        <Route>
          <Layout>
            <Switch>
              <Route
                exact
                path={ROUTES.TERMS_AND_CONDITIONS}
                component={TermsAndConditions}
              />
              <Route
                exact
                path={ROUTES.ABOUT_US}
                component={AboutUs}
              />
              <Route
                exact
                path={ROUTES.DETAILS_EVENT}
                component={EventDetailsScreen}
              />
              <Route
                exact
                path={ROUTES.RESET_PASSWORD_MAIL}
                component={ResetPasswordMail}
              />
              <Route exact path={ROUTES.HOME} component={Home} />
              <AuthUser>
                <Route path="/user/:path?" exact>
                  <User>
                    <Switch>
                      <Route path={ROUTES.PROFILE} component={Profile} />
                      <Route path={ROUTES.MY_EVENTS} component={MyEvents} />
                    </Switch>
                  </User>
                </Route>
                <Route
                  exact
                  path={ROUTES.MORE_INFORMATION}
                  component={MoreInformation}
                />
                <Route exact path={ROUTES.FAVORITES} component={Favorites} />
                <Route
                  exact
                  path={ROUTES.NEW_EVENT}
                  component={CreateNewEvent}
                />
                <Route
                  exact
                  path={ROUTES.STATICS_EVENT}
                  component={StaticsEvent}
                />
                <Route exact path={ROUTES.EDIT_EVENT} component={EditEvent} />
              </AuthUser>
              <Route component={NotFount} />
            </Switch>
          </Layout>
        </Route>
      </Switch>
      <Snackbar
        open={openAlert}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert ? alert.severity : 'success'}
          variant='filled'
        >
          {alert ? alert.message : ''}
        </Alert>
      </Snackbar>
    </BrowserRouter>
  );
};

const mapDispatchToProps = {
  setAlert,
};

export default connect(null, mapDispatchToProps)(App);
