import {
  ADD_EVENTS,
  SOCIAL_LOGIN,
  USER_LOGOUT,
  GET_EVENTS,
  SET_EMAIL,
  SET_USER,
  SET_USER_REGISTER,
  SET_TOKEN_SOCIAL_LOGIN,
  SET_SUCCESS_MESSAGE,
  SET_INTERESTS_EVENTS,
  SET_FILTERED_EVENTS,
  SET_LOADING_SCREEN,
  GET_CATEGORIES,
  GET_BANNER_IMAGES,
  GET_USER_EVENTS,
  SET_ALERT,
  TOGGLE_FAVORITE
} from '../utils/ActionsUtils';

const INITIAL_STATE = {
  user: {},
  events: {},
  categories: [],
  total_categories: 0,
  filtered_events: null,
  banner_images: [],
  draft_events: [],
  published_events: [],
  cancelled_events: [],
  message_success: false,
  message_success_text: '',
  loading_screen: false,
  alert: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case USER_LOGOUT:
      return { ...state, user: {} };
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case ADD_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          ...action.payload,
        },
      };
    case SOCIAL_LOGIN:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case SET_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload.categories],
        total_categories: action.payload.total_categories,
      };
    case SET_USER_REGISTER:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.userPayload.name,
          token: action.userPayload.token,
        },
      };
    case SET_TOKEN_SOCIAL_LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.token,
        },
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        message_success: action.payload.viewMessage,
        message_success_text: action.payload.message,
      };
    case SET_INTERESTS_EVENTS:
      return {
        ...state,
        interests: action.payload.interests,
      };
    case SET_FILTERED_EVENTS:
      return {
        ...state,
        filtered_events: action.payload ? [...action.payload] : null,
      };
    case SET_LOADING_SCREEN:
      return {
        ...state,
        loading_screen: action.payload,
      };
    case GET_BANNER_IMAGES:
      return {
        ...state,
        banner_images: action.payload,
      };
    case GET_USER_EVENTS:
      return {
        ...state,
        draft_events: action.payload.draft_events,
        published_events: action.payload.published_events,
        cancelled_events: action.payload.cancelled_events,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload ? { ...action.payload } : null,
      };
    case TOGGLE_FAVORITE:
      return {
        ...state,
        events: {
          ...action.payload,
        }
      }
    default:
      return state;
  }
};
