import zIndex from '@material-ui/core/styles/zIndex';
import React from 'react';
import { ShareSocial } from 'react-share-social';

const ShareEvent = (props) => {
  const { id } = props;
  const url = `${window.location.origin}/details/event/${id}`;

  const handleSocialShare = (data) => {
    console.log(data);
  };

  return (
    <ShareSocial
      title="Share this event with your friends."
      url={url}
      onSocialButtonClicked={handleSocialShare}
      socialTypes={['facebook', 'twitter', 'linkedin']}
      style={{ zIndex: 1200 }}
    />
  );
};

export default ShareEvent;
