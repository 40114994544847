//const withoutServices = process.env.REACT_APP_WITHOUT_SERVICES === 'true';
import axios from 'axios';
import {
  buildFilters,
  buildFormData,
  EVENT_STATE,
  getConfigToken,
  getLocationEvent,
} from '../utils';
import { buildDateTime } from '../utils/date';

const BASE_URL = process.env.REACT_APP_ENDPOINT_BASE;

export const getEventDetail = (id) => {
  const data = {
    id,
    wherein: 'Colombia',
    img: 'https://bash-web-app.s3-sa-east-1.amazonaws.com/img/img-event-default.png',
    title: 'Lectura de cuentos infantiles',
    qualification: '4,5',
    date: 'Mayo 29 - 100:00 AM',
    organization: 'Panamericana con',
    lenguaje: 'Español',
    price: '$20,000',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    items: [
      {
        title: 'Un cuento infantil',
        description: 'Libro de cuentos Clasicos',
        img: 'https://bash-web-app.s3-sa-east-1.amazonaws.com/img/item_event_1.png',
        price: 'COP 30,000',
        url: 'buscalibros.com',
      },
    ],
    associatedEvents: [
      {
        location: 'Brasil',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Colombia',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Paraguay',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Chile',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'USA',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 4000);
  });
};

export const getFavorites = async (token) => {
  const favorites = {
    favorites: [
      {
        location: 'Brasil',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Colombia',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Paraguay',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Chile',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'USA',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(favorites);
    }, 4000);
  });
};

export const saveUpdateEvent = (dataEvent, publish, userId, eventId = null) => {
  const url = eventId
    ? `${BASE_URL}/users/${userId}/events/${eventId}`
    : `${BASE_URL}/users/${userId}/events`;

  if (!dataEvent.imageFile) {
    const location = getLocationEvent(dataEvent);

    const data = {
      name: dataEvent.name,
      description: dataEvent.description,
      language: dataEvent.language,
      categories: [dataEvent.type],
      start_date: buildDateTime(
        new Date(dataEvent.startDate),
        new Date(dataEvent.startHour)
      ),
      end_date: buildDateTime(
        new Date(dataEvent.endDate),
        new Date(dataEvent.endHour)
      ),
      location: { ...location },
      payment_required: dataEvent.isFree ? 0 : 1,
      payment_description: !dataEvent.isFree
        ? {
            currency: dataEvent.currency,
            amount: dataEvent.price,
          }
        : {},
      links: {
        facebook: dataEvent.facebook ?? null,
        twitter: dataEvent.twitter ?? null,
        instagram: dataEvent.instagram ?? null,
      },
      state: publish ? EVENT_STATE.published : EVENT_STATE.draft,
    };

    if (!eventId) {
      throw Error('No event id found');
    }

    return axios.put(url, data);
  }

  const formData = buildFormData(dataEvent, publish, eventId);
  console.log(formData);

  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const changeEventStatus = (
  eventId,
  userId,
  data,
  state = EVENT_STATE.published
) => {
  if (!eventId) {
    throw Error('No event id found');
  }

  const url = `${BASE_URL}/users/${userId}/events/${eventId}`;
  const body = { ...data, state };
  if (data.categories && data.categories.length > 0) {
    body.categories = [data.categories[0]['id']];
  }

  delete body.image;

  return axios.put(url, body);
};

export const deleteEvent = async (eventId, userId) => {
  const url = `${BASE_URL}/users/${userId}/events/${eventId}?include=categories`;

  try {
    const response = await axios.delete(url);

    return {
      status: true,
      message: 'The event was deleted successfully.',
      data: response.data.data,
    };
  } catch (error) {
    return { status: false, message: 'The event cannot be deleted.' };
  }
};

export const rateEvent = async (eventId, value, token) => {
  const url = `${BASE_URL}/events/${eventId}/ratings`;
  const body = { rating: value };
  const config = getConfigToken(null, token);

  try {
    const response = await axios.post(url, body, config);

    return {
      status: true,
      message: 'The rating was submitted successfully.',
      data: response.data,
    };
  } catch (error) {
    return { status: false, message: "The rating can't be  submitted." };
  }
};

export const toggleFavorite = async (eventId, value, token) => {
  const config = getConfigToken(null, token);
  const url = `${BASE_URL}/events/${eventId}/toggle-favorite`;

  try {
    const response = await axios.get(url, config);

    return {
      status: true,
      data: response.data,
      message: `The event was ${
        value ? 'removed from' : 'added to'
      } favorites.`,
    };
  } catch (error) {
    return { status: false, message: "The favorite can't be changed" };
  }
};

export const associateEvent = async (eventId, userId, token) => {
  const url = `${BASE_URL}/events/${eventId}/users/toggle?include=categories`;
  const body = { resources: [userId] };
  const config = getConfigToken(null, token);

  try {
    const response = await axios.patch(url, body, config);

    return {
      status: true,
      message: 'Enrollment completed.',
      data: response.data,
    };
  } catch (error) {
    return {
      status: false,
      message: "The enrollment in the can't be completed.",
    };
  }
};

export const getEventsActives = () => {
  const actives = {
    actives: [
      {
        location: 'Brasil',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Colombia',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Paraguay',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'Chile',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        location: 'USA',
        title: 'Lectura de cuentos infantiles',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Portuguez',
        price: 'Gratis',
        qualification: '3,0',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(actives);
    }, 2000);
  });
};

export const getEventsPendingPublish = () => {
  const pendings = {
    pendings: [
      {
        id: '183',
        location: 'Colombia',
        title: 'Concierto 1',
        date: '10 Jul - 100:00 am',
        lenguaje: 'Español',
        price: 'Gratis',
        qualification: '3,0',
      },
      {
        id: '98',
        location: 'Panama',
        title: 'Comedia',
        date: '15 agosto - 100:00 am',
        lenguaje: 'Español',
        price: '$40.000',
        qualification: '5,0',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(pendings);
    }, 3000);
  });
};

export const getStaticsEvent = (idEvent) => {
  console.log(
    'Se realiza la consulta de las estadisticas del evento para el id : ' +
      idEvent
  );
  const data = {
    users_register_event: [
      {
        id: 1,
        name: 'Adriana Escobar 1',
        date_register: '14/05/2020',
        email: 'adrianaEscobar@bash.com',
      },
      {
        id: 2,
        name: 'Adriana Escobar 2',
        date_register: '15/05/2020',
        email: 'adrianaEscobar1@bash.com',
      },
      {
        id: 532,
        name: 'Adriana Escobar 532',
        date_register: '1/05/2020',
        email: 'adrianaEscobar2@bash.com',
      },
      {
        id: 213,
        name: 'Adriana Escobar 213',
        date_register: '10/05/2020',
        email: 'adrianaEscobar3@bash.com',
      },
      {
        id: 1231,
        name: 'Adriana Escobar 1231',
        date_register: '23/05/2020',
        email: 'adrianaEscobar4@bash.com',
      },
      {
        id: 1123,
        name: 'Adriana Escobar 1123',
        date_register: '7/05/2020',
        email: 'adrianaEscobar5@bash.com',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 4000);
  });
};

export const sendMenssage = (listIdsUser, message) => {
  const data = {
    users: listIdsUser,
    message,
  };

  console.log(data);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 2000);
  });
};

export const getTypeEvents = () => {
  const data = {
    event_types: ['Deporte', 'Concierto', 'Clase'],
  };

  console.log(data);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 1000);
  });
};

export const getLabelsEvent = (word) => {
  const data = [
    { name: `test word end ${word}`, value: `0 ${word}` },
    { name: `${word} test word start`, value: `1 ${word}` },
    { name: `test ${word} word middle`, value: `2 ${word}` },
  ];

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 500);
  });
};

export const getMaterialsEvent = (word) => {
  const data = [
    {
      name: `test material end ${word}`,
      value: `0-material ${word}`,
      img: 'https://assets.adidas.com/images/h_840,f_auto,q_auto:sensitive,fl_lossy/0a76afaa91be462b800aabb2011f8888_9366/Tapabocas_de_tela_adidas_TALLA_M-L_(PACK_DE_3)_(UNISEX)_Negro_H08837_21_model.jpg',
    },
    {
      name: `${word} test material start`,
      value: `1-material ${word}`,
      img: 'https://assets.adidas.com/images/h_840,f_auto,q_auto:sensitive,fl_lossy/0a76afaa91be462b800aabb2011f8888_9366/Tapabocas_de_tela_adidas_TALLA_M-L_(PACK_DE_3)_(UNISEX)_Negro_H08837_21_model.jpg',
    },
    {
      name: `test ${word} material middle`,
      value: `2-material ${word}`,
      img: 'https://assets.adidas.com/images/h_840,f_auto,q_auto:sensitive,fl_lossy/0a76afaa91be462b800aabb2011f8888_9366/Tapabocas_de_tela_adidas_TALLA_M-L_(PACK_DE_3)_(UNISEX)_Negro_H08837_21_model.jpg',
    },
  ];

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 500);
  });
};

export const getEventsByCategory = async (categoryId, token = null) => {
  try {
    const config = getConfigToken(null, token);

    const response = await axios.get(
      `${BASE_URL}/categories/${categoryId}/events`,
      config
    );

    const data = response.data;

    return { status: true, data: data.data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

export const getEventsByCategories = async (categories = [], token = null) => {
  try {
    const events = {};

    for (let idx in categories) {
      const category = categories[idx];

      const filter = { interests: [category.id], state: 'published' };
      const filterList = buildFilters(filter);

      const response = await searchEventsByFilter(filterList, token);

      if (!response.status) {
        throw new Error({
          error: `Events for category: ${category.id} can't be obtained.`,
        });
      }

      events[category.id] = { category, events: response.data };
    }

    return { status: true, data: events };
  } catch (error) {
    return { status: false, error: error.mesage };
  }
};

/**
 *
 * @param {Array.<Object>} filters
 * @returns
 */
export const searchEventsByFilter = async (filters, token = null) => {
  try {
    const body = {
      filters,
      sort: [
        {
          field: 'updated_at',
          direction: 'asc',
        },
        {
          field: 'name',
          direction: 'asc',
        },
      ],
    };

    const config = getConfigToken(null, token);

    const response = await axios.post(
      `${BASE_URL}/events/search?include=categories`,
      body,
      config
    );

    return { status: true, data: response.data.data };
  } catch (error) {
    debugger;
    let message = 'Error getting events from filters';

    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }

    return { status: false, error: message };
  }
};

export const getEventsByUserId = async (userId, token) => {
  try {
    const config = getConfigToken(null, token);
    const response = await axios.get(
      `${BASE_URL}/users/${userId}/events?include=categories`,
      config
    );
    const data = response.data;

    return { status: true, data: data.data };
  } catch (error) {
    let message = 'Error getting events by user';

    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }

    return { status: false, error: message };
  }
};

export const getEventById = async (eventId, token) => {
  try {
    const config = getConfigToken(null, token);
    const response = await axios.get(
      `${BASE_URL}/events/${eventId}?include=categories`,
      config
    );

    const data = response.data;

    return { status: true, data: data.data };
  } catch (error) {
    console.log(error);
    let message = `Error getting event with id ${eventId}`;

    if (error?.response?.data?.message) {
      // message = error.response.data.message; disable until fix the error message
    }

    return { status: false, error: message };
  }
};
