import { buildDateTime } from '../utils/date';

const FILTERS = {
  interests: { field: 'categories.uuid', operator: 'in', type: 'and' },
  startDate: { field: 'start_date', operator: '>=', type: 'and' },
  endDate: { field: 'start_date', operator: '<=', type: 'and' },
  state: { field: 'state', operator: '=', type: 'and' },
  minPrice: {
    field: 'payment_description->amount',
    operator: '>=',
    type: 'and',
  },
  maxPrice: {
    field: 'payment_description->amount',
    operator: '<=',
    type: 'and',
  },
  lenguaje: { field: 'language', operator: '=', type: 'and' },
  name: { field: 'name', operator: 'like', type: 'and' },
};

export const EVENT_TYPE = {
  in_person: 'in-person',
  online: 'online',
  to_be_defined: 'define',
};

export const LANGUAGES = {
  english: 'EN',
  spanish: 'ES',
  portuguese: 'PT',
};

export const FIELD_TYPE = {
  location: 'location',
  payment_description: 'payment_description',
  links: 'links',
  array: 'array',
  text: 'text',
  date: 'date',
  date_time: 'date_time',
  field: 'field',
  boolean: 'boolean',
};

export const CURRENCY = {
  USD: 'USD',
  COP: 'COP',
};

export const EVENT_STATE = {
  published: 'published',
  draft: 'draft',
  cancelled: 'cancelled',
};

/**
 *
 * @param {Object} filters
 */
export const buildFilters = (filters) => {
  const filterList = [];
  const entries = Object.entries(filters);

  for (let idx = 0; idx < entries.length; idx++) {
    const [name, value] = entries[idx];
    const filter = buildFilter(name, value);

    filterList.push(filter);
  }

  return filterList;
};

/**
 *
 * @param {string} name
 * @param {*} value
 */
export const buildFilter = (name, value) => {
  const filter = { ...FILTERS[name] };
  filter.value = value;

  return filter;
};

/**
 *
 * @param {*} interests
 * @returns []
 */
export const getInterestsSelected = (interests) => {
  const entries = Object.entries(interests);
  const interestsId = entries.reduce((arr, item) => {
    if (item[1]) {
      arr.push(item[0]);
    }
    return arr;
  }, []);

  return interestsId;
};

/**
 *
 * @param {*} dataEvent
 * @returns
 */
export const getLocationEvent = (dataEvent) => {
  switch (dataEvent.location) {
    case EVENT_TYPE.to_be_defined:
      return { to_be_defined: true };

    case EVENT_TYPE.online:
      return { type: 'online', links: [dataEvent.link] };

    case EVENT_TYPE.in_person:
      return { type: 'in-person', address: dataEvent.locationInstructions };

    default:
      return null;
  }
};
/**
 *
 * @param {*} location
 * @returns string
 */
export const getLocationType = (location) => {
  if (!location) return '';

  if (!location.type) {
    return EVENT_TYPE.to_be_defined;
  }

  return location.type;
};

export const getLocationInstructions = (location) => {
  if (!location) return '';

  if (!location.type) return '';

  if (location.type === EVENT_TYPE.online) {
    return '';
  }

  return location.address;
};

export const getLocationLinks = (location) => {
  if (!location) return '';
  if (!location.type) return '';

  if (location.type === EVENT_TYPE.in_person) {
    return '';
  }

  return location.links && location.links.length > 0 ? location.links[0] : '';
};

export const getLocationEventFormData = (dataEvent) => {
  switch (dataEvent.location) {
    case EVENT_TYPE.to_be_defined:
      return [{ name: '[to_be_defined]', value: 1 }];

    case EVENT_TYPE.online:
      return [
        { name: '[type]', value: 'online' },
        { name: '[links][0]', value: dataEvent.link },
      ];

    case EVENT_TYPE.in_person:
      return [
        { name: '[type]', value: 'in-person' },
        { name: '[address]', value: dataEvent.locationInstructions },
      ];

    default:
      return [];
  }
};

/**
 *
 * @param {*} dataEvent
 * @returns FormData
 */
export const buildFormData = (dataEvent, publish, eventId = null) => {
  const formData = new FormData();

  const configs = [
    { field: 'name', name: 'name', type: FIELD_TYPE.text },
    { field: 'imageFile', name: 'image', type: FIELD_TYPE.field },
    { field: 'description', name: 'description', type: FIELD_TYPE.text },
    { field: 'language', name: 'language', type: FIELD_TYPE.text },
    {
      field: 'type',
      name: 'categories',
      type: FIELD_TYPE.array,
      value: [dataEvent.type],
    },
    {
      field: ['startDate', 'startHour'],
      name: 'start_date',
      type: FIELD_TYPE.date_time,
    },
    {
      field: ['endDate', 'endHour'],
      name: 'end_date',
      type: FIELD_TYPE.date_time,
    },
    {
      field: 'isFree',
      name: 'payment_required',
      type: FIELD_TYPE.boolean,
      value: dataEvent.isFree ? 0 : 1,
    },
    { field: 'location', name: 'location', type: FIELD_TYPE.location },
    {
      field: 'payment_description',
      name: 'payment_description',
      type: FIELD_TYPE.payment_description,
    },
    { field: 'links', name: 'links', type: FIELD_TYPE.links },
  ];

  configs.forEach((config) => {
    switch (config.type) {
      case FIELD_TYPE.text:
        formData.append(config.name, dataEvent[config.field]);
        break;
      case FIELD_TYPE.boolean:
        if (config.value !== undefined) {
          formData.append(config.name, config.value);
        } else {
          formData.append(config.name, dataEvent[config.field]);
        }
        break;
      case FIELD_TYPE.field:
        formData.append(config.name, dataEvent[config.field]);
        break;
      case FIELD_TYPE.date_time:
        formData.append(
          config.name,
          buildDateTime(dataEvent[config.field[0]], dataEvent[config.field[1]])
        );
        break;
      case FIELD_TYPE.array:
        if (config.value) {
          config.value.forEach((value, index) =>
            formData.append(`${config.name}[${index}]`, value)
          );
        } else {
          const values = dataEvent[config.field];
          values.forEach((value, index) =>
            formData.append(`${config.name}[${index}]`, value)
          );
        }

        break;
      case FIELD_TYPE.location:
        const fields = getLocationEventFormData(dataEvent);
        fields.forEach((field) =>
          formData.append(`location${field.name}`, field.value)
        );
        break;
      case FIELD_TYPE.payment_description:
        formData.append(
          'payment_description[currency]',
          dataEvent.isFree ? CURRENCY.COP : dataEvent.currency
        );
        formData.append(
          'payment_description[amount]',
          dataEvent.isFree ? 0 : dataEvent.price
        );
        break;
      case FIELD_TYPE.links:
        if (dataEvent.facebook)
          formData.append('links[facebook]', dataEvent.facebook);

        if (dataEvent.twitter)
          formData.append('links[twitter]', dataEvent.twitter);

        if (dataEvent.instagram)
          formData.append('links[instagram]', dataEvent.instagram);

        break;
      default:
        break;
    }
  });

  formData.append('state', publish ? EVENT_STATE.published : EVENT_STATE.draft);
  if (eventId) {
    formData.append('_method', 'PUT');
  }

  return formData;
};

/**
 *
 * @param {string | number} value
 * @param {string} currency
 * @returns string
 */
export const formatCurrency = (value, currency = CURRENCY.USD) => {
  const locale = currency === CURRENCY.USD ? 'en-US' : 'es-CO';
  const formatCurrency = Intl.NumberFormat(locale);

  return formatCurrency.format(value);
};

/**
 *
 * @param {Array} events
 * @returns Object
 */
export const splitEventsUser = (events) => {
  const published = events.filter(
    (event) => event.state === EVENT_STATE.published
  );
  const draft = events.filter((event) => event.state === EVENT_STATE.draft);

  const cancelled = events.filter(
    (event) => event.state === EVENT_STATE.cancelled
  );

  return {
    published_events: published,
    draft_events: draft,
    cancelled_events: cancelled,
  };
};

/**
 *
 * @param {Object} config
 * @param {string} token
 * @returns
 */
export const getConfigToken = (config, token) => {
  if (!token) return config;

  if (!config) {
    const newConfig = { headers: { Authorization: `Bearer ${token}` } };

    return newConfig;
  }

  config['headers'] = {
    ...config['headers'],
    Authorization: `Bearer ${token}`,
  };

  return config;
};
