import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getFavorites } from '../../services/event.services';
import CarouselEvent from '../Event/CarouselEvent';
import Loading from '../Loading';
import ROUTES from '../../utils/RouteUtils';
import ImgFavoritesEmpty from '../../assets/img/favorites-empty.png';
import '../../assets/styles/components/User/Favorites.scss';
import EventDetail from '../Event/EventDetail';

const Favorites = (props) => {

  const [loading, setLoading] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [showDetailEvent, setShowDetailEvent] = useState(false);
  const [eventDetailData, setEventDetailData] = useState({});

  const handleShowLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleRedirectHome = () => {
    props.history.push(ROUTES.HOME);
  };

  const handleShowDetailEvent = () => {
    setShowDetailEvent(true);
  };

  const handleCloseDetailEvent = () => {
    setShowDetailEvent(false);
  };

  useEffect(() => {
    handleShowLoading();
    getFavorites()
      .then((success) => {
        setFavorites([]);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        handleCloseLoading();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='container-header-page-url'>
        <div>
          <Typography>
            <span className='text-header-page-url'>
              Inicio / Favoritos
            </span>
          </Typography>
        </div>
        <div>
          <Typography>
            <span className='text-title-page-url'>
              Favoritos
            </span>
          </Typography>
        </div>
      </div>
      {favorites.length > 0 && (
        <>
          <div className='container-carousel-favorites'>
            <CarouselEvent
              title='favorites'
              events={favorites}
              setShowLoader={setLoading}
              handleShowDetailEventPage={handleShowDetailEvent}
              setEventDetailData={setEventDetailData}
              withOutTitle={true}
            />
          </div>
          { showDetailEvent && (
            <div className='curtain-detail-event'>
              <EventDetail
                {...eventDetailData}
                closebutton={true}
                setShowLoader={setLoading}
                handleShowDetailEventPage={handleShowDetailEvent}
                handleCloseDetailEvent={handleCloseDetailEvent}
                setEventDetailData={setEventDetailData}
                previewEvent={false}
              />
            </div>
          )}
        </>
      )}
      {favorites.length <= 0 && (
        <Grid container component='main' className=''>
          <Grid item xs={12} sm={12} md={3} xl={3} />
          <Grid item xs={12} sm={12} md={6} xl={6} className='img-favorites-empty container-redirect-favorites'>
            <img src={ImgFavoritesEmpty} alt='' />

            <Grid item xs={10} sm={10} md={10} xl={10} className='container-redirect-favorites'>
              <Typography>
                <span className='text-empty-favorites-bash'>
                  No tienes ningún evento favorito
                </span>
              </Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10} xl={10} className='container-redirect-favorites'>
              <Button type='buttton' className='btn-redirect-favorites-bash' variant='contained' color='primary' onClick={handleRedirectHome}>DESCUBRIR EVENTOS</Button>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} />
        </Grid>
      )}
      {loading && (
        <div className='container-loader-carousel'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default Favorites;
