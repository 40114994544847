import React from 'react';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookIcon from '../../assets/img/facebook-icon.svg';
import { loginFacebook } from '../../actions/index';
import '../../assets/styles/components/Athentication/LoginFB.scss';
import { Button, Typography } from '@material-ui/core';

const LoginFB = (props) => {
  const {
    loginFacebook,
    loginSocialSuccess,
    handleSetErrorState,
    handleSetNoErrorState,
  } = props;

  const keyFacebook = process.env.REACT_APP_FACEBOOK_APP_ID;

  const responseFacebook = (response) => {

    if (response.error) {
      handleSetErrorState();
      return;
    }

    if (response.status && response.status === "unknown") {
      handleSetErrorState();
      return;
    }

    handleSetNoErrorState();

    loginFacebook(response, loginSocialSuccess);
  };

  return (
    <FacebookLogin
      appId={keyFacebook}
      fields='name,email,picture'
      callback={responseFacebook}
      render={(renderProps) => (
        <Button
          type='button'
          onClick={renderProps.onClick}
          className='btn-facebook'
        >
          <div className='btn-icon-with-text-center'>
            <img className='facebook' src={FacebookIcon} alt='' />
            <Typography>
              INGRESA CON FACEBOOK
            </Typography>
          </div>
        </Button>
      )}
    />
  );
};

const mapDispatchToProps = {
  loginFacebook,
};

export default connect(null, mapDispatchToProps)(LoginFB);
