import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUserEvents, setAlert } from '../../actions';
import CarouselEventDetailEvent from '../Event/CarouselEventDetailEvent';
import EventDetail from '../Event/EventDetail';
import Loading from '../Loading';
import DeleteEvent from './DeleteEvent';

import { changeEventStatus } from '../../services/event.services';
import Calendar from '../../assets/img/calendar.svg';
import NothingEvent from '../../assets/img/nothing_event.svg';
import ROUTES from '../../utils/RouteUtils';
import { EVENT_STATE } from '../../utils';

import '../../assets/styles/components/User/MyEvents.scss';

const MyEvents = (props) => {
  const { loader, user, draftEvents, publishedEvents, cancelledEvents, getUserEvents, setAlert } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [showDetailEvent, setShowDetailEvent] = useState(false);
  const [eventDetailData, setEventDetailData] = useState({});
  const [showDeleteEvent, setShowDeleteEvent] = useState(false);
  const [idDeleteEvent, setIdDeleteEvent] = useState(-1);

  const getInitialData = async () => {
    getUserEvents(user.id, user.token);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setShowLoader(loader);
  }, [loader]);

  const handleCreateNewEvent = () => {
    props.history.push(ROUTES.NEW_EVENT);
  };

  const handleShowDetailEvent = () => {
    setShowDetailEvent(true);
  };

  const handleCloseDetailEvent = () => {
    setShowDetailEvent(false);
  };

  const handlePublishEvent = (eventId, data) => {
    setShowLoader(true);
    changeEventStatus(eventId, user.id, data)
      .then((success) => {
        setAlert({
          severity: 'success',
          message: `Evento publicado correctamente.`,
        });
        console.log(success);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setShowLoader(false);
        getInitialData();
        setAlert({
          severity: 'success',
          message: `Evento publicado correctamente.`
        });
      });
  };

  const handleCancelEvent = (eventId, data) => {
    setShowLoader(true);

    changeEventStatus(eventId, user.id, data, EVENT_STATE.cancelled)
      .then((success) => {
        console.log(success);
        setAlert({
          severity: 'info',
          message: `Evento cancelado correctamente.`
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setShowLoader(false);
        getInitialData();
      });
  };

  const handleShowDeleteEvent = (idEvent) => {
    setIdDeleteEvent(idEvent);
    setShowDeleteEvent(true);
  };

  const handleCloseDeleteEvent = () => {
    setShowDeleteEvent(false);
  };

  const handleConfirmDeleteEvent = () => {
    getInitialData();
  };

  return (
    <>
      <div className="container-my-events">
        <Grid container component="main" className="">
          {publishedEvents.length === 0 && draftEvents.length == 0 && (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Card
                className="card-new-event"
                elevation={3}
                onClick={handleCreateNewEvent}
              >
                <CardActionArea>
                  <img src={Calendar} alt="" className="img-create-event" />
                  <CardContent>
                    <Typography component="p" align="center">
                      Aun no tienes eventos creados
                    </Typography>
                    <Typography
                      component="p"
                      align="center"
                      className="text-create-event"
                    >
                      Crear evento
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography>
              <span className="title-section-bash">
                Pendientes por publicar
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            {draftEvents.length > 0 && (
              <CarouselEventDetailEvent
                events={draftEvents}
                setShowLoader={setShowLoader}
                handleShowDetailEventPage={handleShowDetailEvent}
                setEventDetailData={setEventDetailData}
                pendingPublish={true}
                isMyEvent={true}
                handlePublishEvent={handlePublishEvent}
                handleShowDeleteEvent={handleShowDeleteEvent}
              />
            )}
            {draftEvents.length === 0 && (
              <div className="nothin-event">
                <Typography>
                  {' '}
                  No tienes ningún evento pendiente por publicar
                </Typography>
                <img src={NothingEvent} alt="" />
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography>
              <span className="title-section-bash">Activos</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            {publishedEvents.length > 0 && (
              <CarouselEventDetailEvent
                events={publishedEvents}
                isMyEvent={true}
                setShowLoader={setShowLoader}
                handleCancelEvent={handleCancelEvent}
                handleShowDetailEventPage={handleShowDetailEvent}
                setEventDetailData={setEventDetailData}
              />
            )}
            {publishedEvents.length === 0 && (
              <div className="nothin-event">
                <Typography> No tienes ningún evento activo</Typography>
                <img src={NothingEvent} alt="" />
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography>
              <span className="title-section-bash">Cancelados</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            {cancelledEvents.length > 0 && (
              <CarouselEventDetailEvent
                events={cancelledEvents}
                isMyEvent={true}
                setShowLoader={setShowLoader}
                handleCancelEvent={handleCancelEvent}
                handleShowDetailEventPage={handleShowDetailEvent}
                setEventDetailData={setEventDetailData}
              />
            )}
            {publishedEvents.length === 0 && (
              <div className="nothin-event">
                <Typography> No tienes ningún evento cancelado</Typography>
                <img src={NothingEvent} alt="" />
              </div>
            )}
          </Grid>
        </Grid>
        {showLoader && (
          <div className="container-loader-carousel">
            <Loading />
          </div>
        )}
        {showDetailEvent && (
          <div className="curtain-detail-event">
            <EventDetail
              {...eventDetailData}
              items={[]}
              associatedEvents={[]}
              closebutton={true}
              setShowLoader={setShowLoader}
              handleShowDetailEventPage={handleShowDetailEvent}
              handleCloseDetailEvent={handleCloseDetailEvent}
              setEventDetailData={setEventDetailData}
              previewEvent={false}
            />
          </div>
        )}
      </div>
      {showDeleteEvent && (
        <DeleteEvent
          show={showDeleteEvent}
          closeDeleteEvent={handleCloseDeleteEvent}
          handleConfirmDeleteEvent={handleConfirmDeleteEvent}
          setShowLoader={setShowLoader}
          id={idDeleteEvent}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loading_screen,
    user: state.user,
    draftEvents: state.draft_events,
    publishedEvents: state.published_events,
    cancelledEvents: state.cancelled_events
  };
};

const mapDispatchToProps = {
  getUserEvents, setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEvents);
