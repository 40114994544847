import React, { useState } from 'react';
import { connect } from 'react-redux';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import CustomMenu from './CustomMenu';

const UserMenu = (props) => {

  const {
    user,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div role='presentation' className='menu-icons' onClick={handleClick}>
        {!user.image && <AccountCircleIcon className='img-user' />}
        {user.image && <img src={user.image} className='img-user' alt='' />}
        <div className='arrow-icon'>
          <KeyboardArrowDownRoundedIcon />
        </div>
      </div>
      <CustomMenu
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
      />
    </>
  );
};

const mapPropsToDispatch = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapPropsToDispatch, null)(UserMenu);
