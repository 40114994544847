const RESPONSIVE_CAROUSEL_FILTER_EVENTS = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1050 },
    items: 6,
  },
  superLargeDesktop2: {
    breakpoint: { max: 1050, min: 600 },
    items: 3.2,
  },
  superLargeDesktop3: {
    breakpoint: { max: 750, min: 400 },
    items: 2.5,
  },
  superLargeDesktop4: {
    breakpoint: { max: 400, min: 100 },
    items: 1.3,
  },
};

const RESPONSIVE_CAROUSEL_EVENTS = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  mediumLargeDesktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 5.5,
  },
  LargeDesktop: {
    breakpoint: { max: 1600, min: 1490 },
    items: 5.5,
  },
  mediumDesktop1: {
    breakpoint: { max: 1490, min: 1350 },
    items: 5,
  },
  mediumDesktop2: {
    breakpoint: { max: 1350, min: 1240 },
    items: 4.5,
  },
  mediumDesktop3: {
    breakpoint: { max: 1240, min: 1200 },
    items: 3.9,
  },
  smallDesktop: {
    breakpoint: { max: 1200, min: 1050 },
    items: 3.5,
  },
  mobileScreen1: {
    breakpoint: { max: 1050, min: 940 },
    items: 3.8,
  },
  mobileScreen2: {
    breakpoint: { max: 940, min: 885 },
    items: 3.5,
  },
  mobileScreen3: {
    breakpoint: { max: 885, min: 820 },
    items: 3.2,
  },
  mobileScreen4: {
    breakpoint: { max: 820, min: 790 },
    items: 3.1,
  },
  mobileScreen5: {
    breakpoint: { max: 790, min: 720 },
    items: 3,
  },
  mobileScreen6: {
    breakpoint: { max: 720, min: 660 },
    items: 2.7,
  },
  mobileScreen7: {
    breakpoint: { max: 660, min: 590 },
    items: 2.4,
  },
  mobileScreen8: {
    breakpoint: { max: 590, min: 510 },
    items: 2.1,
  },
  mobileScreen9: {
    breakpoint: { max: 510, min: 450 },
    items: 1.8,
  },
  mobileScreen10: {
    breakpoint: { max: 450, min: 380 },
    items: 1.5,
  },
  mobileScreen11: {
    breakpoint: { max: 380, min: 350 },
    items: 1.3,
  },
  mobileScreen12: {
    breakpoint: { max: 350, min: 320 },
    items: 1.1,
  },
  mobileScreen13: {
    breakpoint: { max: 320, min: 100 },
    items: 1,
  },
};

const RESPONSIVE_CAROUSEL_EVENTS_DETAIL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6.3,
  },
  mediumLargeDesktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 4.1,
  },
  LargeDesktop: {
    breakpoint: { max: 1600, min: 1490 },
    items: 3,
  },
  mediumDesktop1: {
    breakpoint: { max: 1490, min: 1350 },
    items: 3.5,
  },
  mediumDesktop2: {
    breakpoint: { max: 1350, min: 1240 },
    items: 3.1,
  },
  mediumDesktop3: {
    breakpoint: { max: 1240, min: 1200 },
    items: 2.7,
  },
  smallDesktop: {
    breakpoint: { max: 1200, min: 1050 },
    items: 2.5,
  },
  mobileScreen1: {
    breakpoint: { max: 1050, min: 940 },
    items: 2.85,
  },
  mobileScreen2: {
    breakpoint: { max: 940, min: 885 },
    items: 3,
  },
  mobileScreen3: {
    breakpoint: { max: 885, min: 820 },
    items: 2.5,
  },
  mobileScreen4: {
    breakpoint: { max: 820, min: 790 },
    items: 2.4,
  },
  mobileScreen5: {
    breakpoint: { max: 790, min: 720 },
    items: 2.3,
  },
  mobileScreen6: {
    breakpoint: { max: 720, min: 660 },
    items: 2.5,
  },
  mobileScreen7: {
    breakpoint: { max: 660, min: 590 },
    items: 2.2,
  },
  mobileScreen8: {
    breakpoint: { max: 590, min: 510 },
    items: 1.8,
  },
  mobileScreen9: {
    breakpoint: { max: 510, min: 450 },
    items: 1.8,
  },
  mobileScreen10: {
    breakpoint: { max: 450, min: 380 },
    items: 1.5,
  },
  mobileScreen11: {
    breakpoint: { max: 380, min: 350 },
    items: 1.3,
  },
  mobileScreen12: {
    breakpoint: { max: 350, min: 320 },
    items: 1.1,
  },
  mobileScreen13: {
    breakpoint: { max: 320, min: 100 },
    items: 1,
  },
};

export {
  RESPONSIVE_CAROUSEL_FILTER_EVENTS,
  RESPONSIVE_CAROUSEL_EVENTS,
  RESPONSIVE_CAROUSEL_EVENTS_DETAIL,
};
