import { Button, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import Loading from '../Loading';
import { setAlert } from '../../actions';
import { forgotPassword } from '../../services/authentication-services';
import ROUTES from '../../utils/RouteUtils';

import '../../assets/styles/components/Athentication/Login.scss';

const ResetPassword = (props) => {
  const { setAlert } = props;

  const history = useHistory();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email: '' });

  // const [alert, setAlert] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') return;

    setOpenAlert(false);
    setAlert(null);
  };

  const submitForm = async (event) => {
    event.preventDefault();

    setError(false);
    setLoading(true);
    const response = await forgotPassword(form.email);
    setLoading(false);

    if (!response.status) {
      setError(true);
      //setOpenAlert(true);

      setAlert({ severity: 'error', message: response.message });
      return;
    }

    //setOpenAlert(true);
    setAlert({
      severity: 'success',
      message: 'We have emailed your password reset link!',
    });
    history.push(ROUTES.READY_LOGIN);
  };

  return (
    <>
      <div>
        <div>
          <Typography>
            <span className="welcome-bash">Cambio de contraseña</span>
          </Typography>
          <Typography className="text-welcome-register">
            Te enviaremos un e-mail para que puedas cambiar tu contraseña
          </Typography>
        </div>
        <form
          id="ready_form"
          nameç="ready_form"
          className=""
          autoComplete="off"
          onSubmit={submitForm}
        >
          <div className="container-input">
            <TextField
              onChange={handleChange}
              error={error}
              color="primary"
              className="input-login"
              id="email"
              label="email"
              name="email"
              type="email"
              required
              helperText={error ? 'Error en el mail' : ''}
            />
          </div>
          <Button
            type="submit"
            className="btn-login-bash"
            variant="contained"
            color="primary"
          >
            CONFIRMAR
          </Button>
        </form>
      </div>
      {loading && <Loading />}
    </>
  );
};

const mapDispatchToProps = {
  setAlert,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
