import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getEventById } from '../../services/event.services';
import Loading from '../Loading';
import CreateNewEvent from './CreateNewEvent';

const EditEvent = (props) => {
  const params = useParams();
  const { id } = params;
  const { history } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);

  const getEvent = async () => {
    const response = await getEventById(id);
    setLoading(false);

    if (!response.status) {
      setError(response.error);
      return;
    }
    setEvent(response.data);
  };

  useEffect(() => {
    setLoading(true);
    getEvent();
  }, [id]);

  return (
    <>
      {!error && !loading && (
        <CreateNewEvent event={event} eventId={id} history={history} />
      )}
      {error && (
        <div className="content">
          <h1>{error}</h1>
        </div>
      )}
      {loading && (
        <div className="container-loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default EditEvent;
