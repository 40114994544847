import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ImgMask from '../assets/img/mask.png';
import BashIcon from '../assets/img/bash-icon.png';

import '../assets/styles/containers/Authentication.scss';

class Authentication extends React.Component {

  render() {

    const handleBack = () => {
      this.props.history.goBack();
    };

    return (
      <Grid container component='main' className='root-authentication-bash'>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={5} xl={5} className='img-authentication' component={Paper} zeroMinWidth>
          <div className='container-text-experiences-bash'>
            <Typography align='center'>
              <span className='experience-bash'>
                Experiencias únicas en un solo lugar
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={7} xl={7} component={Paper} elevation={6} square className='container-mask-bash'>
          <img src={ImgMask} alt='' className='img-mask' />
          <div className='container-form-authentication'>
            <ArrowBackIcon className='btn-back' type='button' onClick={handleBack} />
            <img className='img-bash' src={BashIcon} alt='' />
          </div>
          <div className='container-login'>
            {this.props.children}
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default withRouter(Authentication);
