import React from 'react';
import { Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core';

import '../assets/styles/components/ItemInformation.scss';
import FamilyImg from '../assets/img/familily.png';

const ItemInformation = (props) => {

  const {
    id,
    img,
    title,
    handleSelectItemId,
    selected,
  } = props;

  const [checked, setChecked] = React.useState(selected || false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleSelectItemId(id, event.target.checked);
  };

  return (
    <>
      <Paper className='card-information' elevation={3} style={{ borderRadius: '21px' }}>
        <div className='checked-information'>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name='item-1'
                color='primary'
              />
            )}
          />
        </div>
        <div className='img-information'>
          <img alt='' src={img || FamilyImg} />
        </div>
        <div className='name-information'>
          <Typography
            style={{
              whiteSpace: 'break-spaces',
              display: 'flex',
              width: '100%',
              wordBreak: 'break-all',
              padding: '0 1rem',
            }}
          >
            <span>
              {title || 'Familia'}
            </span>
          </Typography>
        </div>
      </Paper>
    </>
  );
};

export default ItemInformation;
