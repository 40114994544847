import React from 'react';
import { Button, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

import '../../assets/styles/components/MailModal.scss';

const MailModal = (props) => {

  const {
    show,
    handleCloseModal,
    handleClick,
  } = props

  const handleClickBtn = () => {
    handleCloseModal();
    handleClick();
  };

  const body = (
    <div className='container-message-mail-bash'>
      <MailIcon className='icon-mail' style={{ fontSize: 50 }}/>
      <Typography><span className='view-mail'>Revisa tu correo</span></Typography>
      <div className='container-message-bash'>
        <Typography>te hemos enviado un correo, para que confirmes tu cuenta</Typography>
      </div>
      <Button className='btn-confirm-email' variant='contained' color='primary' type='button' onClick={handleClickBtn}>Entendido</Button>
    </div>
  );
  return (
    <div className='container-mail-modal-bash' style={{display: `${show ? 'block' : 'none'}`}}>
      <div className='modal-mobile'>
        {body}
      </div>
    </div>
  );
};

export default (MailModal);
