import React from 'react';
import { connect } from 'react-redux';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Grid, Typography } from '@material-ui/core';
import { setMessageSuccess } from '../../actions/index';

const MessageSuccess = (props) => {
  const {
    message,
    setMessageSuccess,
  } = props;

  const handleCloseMessage = () => {
    setMessageSuccess(false, '');
  };

  return (
    <>
      <Grid container component='main' className='container-message-success'>
        <Grid item xs={11} sm={11} md={11} xl={11} className='container-message-text-success'>
          <Typography>
            <span className='message-text-success'>
              {message}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} xl={1} className='container-message-text-success'>
          <HighlightOffIcon fontSize='small' onClick={handleCloseMessage} />
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = {
  setMessageSuccess,
};

const mapPropsToDispatch = (state) => {
  return {
    message: state.message_success_text,
  };
};

export default connect(mapPropsToDispatch, mapDispatchToProps)(MessageSuccess);
