import { Paper } from '@material-ui/core';
import React from 'react';
import EventDetailForm from '../EventDetailForm';

const StepPreviewEvent = (props) => {
  const { form, user } = props;
  const { price, currency, isFree } = form;

  const getLinks = () => {
    if (form.twitter || form.facebook || form.instagram) {
      return {
        twitter: form.twitter,
        facebook: form.facebook,
        instagram: form.instagram,
      };
    }

    return null;
  };

  const links = getLinks();

  return (
    <>
      <Paper elevation={3}>
        <EventDetailForm
          {...form}
          price={price}
          currency={isFree ? '' : `${currency} $`}
          links={links}
          items={[]}
          associatedEvents={[]}
          user={user}
          closebutton={false}
          setShowLoader={null}
          handleCloseDetailEvent={null}
          previewEvent={true}
        />
      </Paper>
    </>
  );
};

export default StepPreviewEvent;
