import axios from "axios";

const BASE_URL = process.env.REACT_APP_ENDPOINT_BASE;

export const getBannerImages = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/banners`);
    const data = response.data;

    return { status: true, images: data };
  } catch (error) {
    return { status: false, message: 'Error getting banner images', error };
  }
};

export const getOptionsInterests = () => {
  const data = {
    options: [
      { name: 'Familia', value: 'family' },
      { name: 'Amigos', value: 'friends' },
      { name: 'Deporte', value: 'sports' },
      { name: 'Gastroniomia', value: 'gastronomy' },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 500);
  });
};

export const getTest = () => {
  const data = [];

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 500);
  });
};
