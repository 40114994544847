import React from 'react';
import { connect } from 'react-redux';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import NewEventButton from './NewEventButton';
import UserMenu from './UserMenu';
import Notifications from './Notifications';
import ROUTES from '../../utils/RouteUtils';
import MenuMobile from './MenuMobile';

import '../../assets/styles/containers/Header.scss';

const Header = (props) => {

  const { user } = props;

  return (
    <>
      <header className='container-header'>
        <div className='header'>
          <Link className='logo' to={ROUTES.HOME}>
            <Typography>Bash</Typography>
          </Link>
          <div className='header-right menu-client'>
            <div className='app-search'>
              <div className='app-search-box'>
                <MenuMobile />
              </div>
            </div>
          </div>
          <div className='header-right menu-desktop'>
            {user.token && (
              <div className='app-search'>
                <div className='app-search-box'>
                  <Notifications />
                </div>
              </div>
            )}
            {user.token && (
              <div className='app-search'>
                <div className='app-search-box'>
                  <Link to={ROUTES.FAVORITES}>
                    <div className='favorites-bash'>
                      <FavoriteIcon fontSize='large' />
                      <Typography>Favoritos</Typography>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            <div className='app-search'>
              <div className='app-search-box'>
                <NewEventButton />
              </div>
            </div>
            {user.token && (
              <div className='user-menu app-search'>
                <div className='app-search-box'>
                  <UserMenu />
                </div>
              </div>
            )}
            {!user.token && (
              <div className='app-search'>
                <div className='app-search-box'>
                  <Link to={ROUTES.READY_LOGIN}>
                    <Typography className='text-header-bash'>Iniciar Sesión</Typography>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Header);
