import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import ItemCategories from './ItemCategories';

const SelectCategories = (props) => {

  const {
    show,
    closeSelectCategories,
    handleSelectCategory,
    categories,
    selectedCategories,
    isPreference,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(categories)

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={closeSelectCategories}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className='title-select-categories'>
          {!isPreference ? 'Selecciona las categorias de tu evento' : 'Selecciona las prefenrencias que más te gusten' }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!isPreference ? 'Puedes Seleccionar las categorias que más se acomoden a tu evento.' : '' }
          </DialogContentText>
          <DialogContent>
            <Grid container component='main' className=''>
              {categories.map((category) => {
                return (
                  <Grid item xs={6} sm={3} md={4} xl={4} className='container-selected-categories' key={`id_item_${category.id}`}>
                    <ItemCategories
                      id={category.id}
                      title={category.name}
                      handleSelectItemId={handleSelectCategory}
                      selectedCategories={selectedCategories}
                    />
                  </Grid>
                );
              })
              }
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Grid container component='main' className=''>
            <Grid item xs={12} sm={12} md={12} xl={12} className='container-selected-categories'>
              <Button type='button' className='btn-login-bash' variant='contained' color='primary' onClick={closeSelectCategories}>CONTINUAR</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default SelectCategories;
