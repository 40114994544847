import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/styles/components/Event/CarouselEvent.scss';
import { RESPONSIVE_CAROUSEL_EVENTS_DETAIL } from '../../utils/CarouselConst';
import CardEvent from './CardEvent';
import { CustomLeftArrow, CustomRightArrow } from './CustomButtons';

const CarouselEventDetailEvent = (props) => {
  const responsive = RESPONSIVE_CAROUSEL_EVENTS_DETAIL;
  const {
    events,
    setShowLoader,
    handleShowDetailEventPage,
    setEventDetailData,
    pendingPublish,
    isMyEvent,
    handlePublishEvent,
    handleCancelEvent,
    handleShowDeleteEvent,
  } = props;

  const handleShowDetailEvent = (id, event) => {
    setShowLoader(true);

    setEventDetailData(event);
    handleShowDetailEventPage();

    setShowLoader(false);
  };

  return (
    <>
      <div className="">
        <div className="content-carousel-detail-event">
          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={false}
            infinite={false}
            keyBoardControl={true}
            transitionDuration={300}
            arrows={true}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[
              'mobileScreen1',
              'mobileScreen2',
              'mobileScreen3',
              'mobileScreen4',
              'mobileScreen5',
              'mobileScreen6',
              'mobileScreen7',
              'mobileScreen8',
              'mobileScreen9',
              'mobileScreen10',
              'mobileScreen11',
              'mobileScreen12',
              'mobileScreen13',
            ]}
            dotListClass="custom-dot-list-style"
            focusOnSelect={false}
            itemClass="item-carrousel"
          >
            {events.map((event) => (
              <CardEvent
                key={`item_event_${event['id']}`}
                id={event['id']}
                img={event['image']}
                location={event['location']}
                title={event['name']}
                date={event['start_date']}
                lenguaje={event['language']}
                price={event['price']}
                qualification={event['qualification']}
                event={event}
                handleShowDetailEvent={handleShowDetailEvent}
                pendingPublish={pendingPublish}
                isMyEvent={isMyEvent}
                handleCancelEvent={handleCancelEvent}
                handlePublishEvent={handlePublishEvent}
                handleShowDeleteEvent={handleShowDeleteEvent}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

CarouselEventDetailEvent.propTypes = {
  title: PropTypes.string,
  events: PropTypes.array,
  handleShowDetailEvent: PropTypes.any,
};

export default CarouselEventDetailEvent;
