// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, Typography } from '@material-ui/core';
import ListItemsSelected from './ListItemsSelected';

const AutocompleteSearch = (props) => {

  const {
    keyId,
    labelsSelected,
    handleAddLabel,
    handleSearchService,
    handleDeleteItem,
  } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState({});

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    return () => {
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleAddBtn = () => {
    handleAddLabel(currentValue);
    setCurrentValue('');
  };

  const handleChangeText = (event) => {
    const { value } = event.target;

    if (value !== '') {
      setLoading(true);

      handleSearchService(value)
        .then((success) => {
          setOptions(Object.keys(success).map((key) => success[key]));
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChangeListSelected = (event, newValue) => {
    setCurrentValue(newValue);
  };

  return (
    <>
      <section>
        <Grid container component='section' className=''>
          <Grid item xs={12} sm={12} md={9} xl={9}>
            <Autocomplete
              id={`autocomplete-bash-${keyId}`}
              style={{ width: '100%' }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={handleChangeListSelected}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className='input-autocomplete'
                  color='primary'
                  label='Ingresa una palabra clave'
                  type='text'
                  name='search-input'
                  value={currentValue}
                  onChange={handleChangeText}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color='primary' size={20} /> : null}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3}>
            <Button className='btn-outline-bash' variant='outlined' color='primary' onClick={handleAddBtn}>
              <Typography className='btn-outline-bash-title'>
                Agregar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </section>
      <section className='mt-2'>
        <ListItemsSelected items={labelsSelected} handleDeleteItem={handleDeleteItem} />
      </section>
    </>
  );
};

export default AutocompleteSearch;
