import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import React from 'react';
import { CURRENCY } from '../../../utils';

const StepPrice = (props) => {

  const {
    form,
    handleChange,
  } = props;

  const handleChangePrice = (isFree) => {
    handleChange({ target: { name: 'isFree', value: isFree } });
  };

  return (
    <>
      <Typography className='sub-title-step'>
        Selecciona el tipo de entrada de tu evento
      </Typography>
      <div className='mt-3 container-left text-center'>
        <Box display='flex' justifyContent='center'>
          <Box className='box-location-btns'>
            <Button className={!form.isFree ? 'btn-selected' : ''} variant='outlined' color='primary' onClick={() => handleChangePrice(false)}>
              <Typography className='btn-outline-bash-title'>
                Pago
              </Typography>
            </Button>
          </Box>
          <Box className='box-location-btns'>
            <Button className={form.isFree ? 'btn-selected' : ''} variant='outlined' color='primary' onClick={() => handleChangePrice(true)}>
              <Typography className='btn-outline-bash-title'>
                Grátis
              </Typography>
            </Button>
          </Box>
        </Box>
      </div>
      {!form.isFree && (
        <div className='text-center mt-3 mb-3'>
          <Box display='flex' justifyContent='center'>
            <Box className='box-location-btns'>
              <FormControl className='container-currency-select'>
                <InputLabel id='currency-label'>Moneda</InputLabel>
                <Select
                  labelId='currency-label'
                  id='currency'
                  name='currency'
                  value={form.currency}
                  onChange={handleChange}
                >
                  <MenuItem value={CURRENCY.COP}>COP</MenuItem>
                  <MenuItem value={CURRENCY.USD}>USD</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className='box-location-btns'>
              <TextField
                id='price'
                name='price'
                label='Ingresa valor'
                type='number'
                onChange={handleChange}
                value={form.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default StepPrice;
