import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SearchEvents from './SearchEvents';

import DefaultBanner from '../../assets/img/default-banner.jpg';
import HomeImage1 from '../../assets/img/header-principal-page.png';
import HomeImage2 from '../../assets/img/banner-conciertos.png';

const FilterHome = (props) => {
  const { setShowLoader, bannerImages } = props;

  const handleSearchEvents = (form) => {
    console.log('Search events!!!!');
    console.log(form);
  };

  const images = [HomeImage1, HomeImage2];
  const [indexImage, setIndexImage] = useState(0);
  const [imageBackground, setImageBackground] = useState(DefaultBanner);

  useEffect(() => {
    if (!bannerImages || bannerImages.length === 0) return;

    setImageBackground(bannerImages[0]);
    setIndexImage(1);
  }, [bannerImages]);

  useEffect(() => {
    if (!bannerImages || bannerImages.length === 0) return;

    const id = setTimeout(() => {
      const index = (indexImage + 1) % bannerImages.length;

      setIndexImage(index);
      setImageBackground(bannerImages[indexImage]);
    }, 10000);

    return () => clearTimeout(id);
  }, [imageBackground]);

  return (
    <>
      <div className="container-img">
        <img src={imageBackground} alt="Snow" />
        <div className="centered center">
          <Typography className="title-search">
            Encuentra el plan ideal
          </Typography>
          <div className="description-search-events">
            <Typography>
              Te ayudamos a conectar con personas de todo el mundo
            </Typography>
            <Typography>
              que estan compartiendo sus conocimiento en un solo lugar
            </Typography>
          </div>
          <SearchEvents
            handleSearchEvents={handleSearchEvents}
            setShowLoader={setShowLoader}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerImages: state.banner_images,
  };
};

export default connect(mapStateToProps, null)(FilterHome);
