import React from 'react';
import '../../assets/styles/components/Pages/index.scss';

const AboutUs = () => (
    <>
        <div className='container'>
            <h2 id="terms-and-conditions-for-inbash">About</h2>
            <p>Bash was created by a small group of friends as a virtual space to bring you closer to the places, people and experiences that will fuel your spirit and ignite your passion. From concerts, seminars, classes, fundraisers to yoga, meditation, and casual gatherings that you can attend, create or organize! Is your new site, it is your decision</p>
        </div>
    </>
);

export default AboutUs;
