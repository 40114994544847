import axios from 'axios';
import { getConfigToken } from '../utils';

const BASE_URL = process.env.REACT_APP_ENDPOINT_BASE;

export const getCategories = async (queryParams, token = null) => {
  try {
    const params = { ...queryParams };
    const config = getConfigToken({ params: params }, token);
    const response = await axios.get(`${BASE_URL}/categories`, config);

    const data = response.data;

    return { status: true, data: data.data, total: data.meta.total };
  } catch (error) {
    return { status: false, error: error.message };
  }
};
