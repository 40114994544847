import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState, useRef, useEffect } from 'react';
import { Redirect } from 'react-router';

import Loading from '../Loading';
import useQuery from '../../hooks/useQuery';
import ROUTES from '../../utils/RouteUtils';
import '../../assets/styles/components/Athentication/ResetPasswordMail.scss';
import { changePassword } from '../../services/user.services';
import { setAlert } from '../../actions';
import { connect } from 'react-redux';

const ResetPasswordMail = (props) => {
  const { setAlert, history } = props;
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const passwordEl = useRef();
  const passwordConfirmationEl = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const toogleShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toogleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(passwordEl.current.value);
    console.log(passwordConfirmationEl.current.value);

    setLoading(true);

    const email = query.get('email');
    const token = query.get('token');
    const password = passwordEl.current.value;
    const passwordConfirmation = passwordConfirmationEl.current.value;

    const form = {
      new_password: password,
      new_password_confirm: passwordConfirmation,
    };

    const response = await changePassword(form, email, token);
    setLoading(false);

    if (!response.status) {
      setAlert({ severity: 'error', message: response.message });
      return;
    }

    setAlert({ severity: 'success', message: response.message });
    history.push(ROUTES.HOME);
  };

  useEffect(() => {
    console.log(passwordEl.current);
  }, []);

  if (!query.has('token')) {
    return <Redirect to={ROUTES.HOME}></Redirect>;
  }

  return (
    <>
      <div className="container-header-page-url">
        <div>
          <Typography>
            <span className="text-header-page-url">
              Inicio / Cambio Contraseña
            </span>
          </Typography>
        </div>
        <div>
          <Typography>
            <span className="text-title-page-url">Cambio Contraseña</span>
          </Typography>
        </div>
        <div className="row change-password-container">
          <Card>
            <div className="row change-password-section">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <TextField
                    color="primary"
                    className="input-login input-change-password"
                    label="Nueva Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    required
                    inputRef={passwordEl}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toogleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="row mt-3">
                  <TextField
                    inputRef={passwordConfirmationEl}
                    color="primary"
                    className="input-login input-change-password"
                    label="Confirmar Contraseña"
                    type={showConfirmPassword ? 'text' : 'password'}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toogleShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="row mt-5">
                  <Button
                    type="submit"
                    className="btn-login-bash"
                    variant="contained"
                    color="primary"
                  >
                    CONFIRMAR
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </div>
      </div>
      {loading && (
        <div className="container-loader-carousel">
          <Loading />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  setAlert,
};

export default connect(null, mapDispatchToProps)(ResetPasswordMail);
