import { CircularProgress } from '@material-ui/core';
import React from 'react';
import '../assets/styles/components/Loading.scss';

const Loading = () => {

  return (
    <div className='parent-loading'>
      <CircularProgress />
    </div>
  );
};

export default Loading;
