import { Button, Grid, Paper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FacebookIcon from '@material-ui/icons/Facebook';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InstagramIcon from '@material-ui/icons/Instagram';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, { useState } from 'react';
import DefaultImgEvent from '../../assets/img/img-event-default.png';
import '../../assets/styles/components/Event/DetailEvent.scss';
import { formatCurrency } from '../../utils';
import { formatDateTime } from '../../utils/date';
import CarouselEventDetailEvent from './CarouselEventDetailEvent';

const EventDetailForm = (props) => {
  let {
    image,
    name,
    qualification,
    startDate,
    organization,
    language,
    description,
    payment_description,
    items,
    links,
    associatedEvents,
    user,
    isFree,
    currency,
    price,
  } = props;

  const { closebutton, setShowLoader, handleCloseDetailEvent, previewEvent } =
    props;

  const [formMessage, setFormMessage] = useState({
    message: '',
  });

  const handleShowDetailEvent = () => {
    console.log('Show new event');
  };

  const setEventDetailDataLocal = (data) => {
    console.log(data);
    //img = data.img;
    //title = data.title;
    qualification = data.qualification;
    //date = data.date;
    organization = data.organization;
    //lenguaje = data.lenguaje;
    price = data.price;
    description = data.description;
    items = data.items;
    associatedEvents = data.associatedEvents;
  };

  const necesaryItems = [];
  Object.keys(items).map((i) => {
    necesaryItems.push(
      <div className="content-items-necesary" key={`Necesary_Items_${i}`}>
        {items[i]['title']}
        <div className="contetnt-item-description">
          <img src={items[i]['image']} alt="" />
          <Typography>
            <span className="description-item">{items[i]['description']}</span>
          </Typography>
          <Typography>
            <span className="price-item">{items[i]['price']}</span>
          </Typography>
          <Typography>
            <span className="url-item">{items[i]['url']}</span>
          </Typography>
        </div>
      </div>
    );
    return necesaryItems;
  });

  return (
    <>
      <div className="container-detail-event-card">
        {closebutton && (
          <>
            <div className="back-btn-phones">
              <ArrowBackIcon onClick={handleCloseDetailEvent} />
            </div>
            <div className="container-close-btn">
              <HighlightOffIcon
                onClick={handleCloseDetailEvent}
                type="button"
              />
            </div>
          </>
        )}
        <div className="container-img-detail-event-card">
          <img src={image || DefaultImgEvent} alt="" />
        </div>
        <Paper className="container-info-detail-event">
          <div className="container-basic-info-event">
            <Grid container component="main" className="">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                className="container-secction-info-detail-event"
              >
                <Typography>
                  <span className="title-detail-event">{name}</span>
                </Typography>
              </Grid>
              {!previewEvent && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  xl={6}
                  className="container-secction-info-detail-event"
                >
                  <div className="qualification-event-detail">
                    {qualification}
                    <StarBorderIcon />
                    <Typography>
                      <span className="qualification-comments-desktop">
                        Ver todos los comentarios (50)
                      </span>
                      <span className="qualification-comments-mobile">
                        (50)
                      </span>
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container component="main" className="">
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">¿Cuándo?</span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {formatDateTime(new Date(startDate))}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">
                  ¿Quién lo organiza?
                </span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {user.name}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">Idioma</span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {language}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              className="container-secction-info-detail-event"
            >
              <Typography>
                <span className="title-secction-detail-event">Precio</span>
              </Typography>
              <Typography>
                <span className="description-secction-detail-event">
                  {isFree
                    ? 'Free'
                    : `${currency} ${formatCurrency(price, currency)}`}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <div className="container-detail-event">
          <div>
            <Typography>
              <span className="title-event-detail-event">
                Acerca del evento
              </span>
            </Typography>
          </div>
          <div className="description-container-event-detail">
            <Typography>
              <span className="description-event-detail-event">
                {description}
              </span>
            </Typography>
          </div>
        </div>
        <div className="container-secction container-detail-event">
          {!previewEvent && necesaryItems.length > 0 && (
            <>
              <div>
                <Typography>
                  <span className="title-event-detail-event">
                    ¿Qué necesitas?
                  </span>
                </Typography>
              </div>
              <div className="grid-necesary-items description-container-event-detail">
                {necesaryItems}
              </div>
            </>
          )}
        </div>
        {links && (
          <div className="container-secction container-detail-event">
            <div>
              <Typography>
                <span className="title-event-detail-event">
                  Comparte con tus amigos
                </span>
              </Typography>
              <div className="container-social-icons">
                {links.twitter ? (
                  <a href={links.twitter} target="_blank">
                    <TwitterIcon />
                  </a>
                ) : (
                  <TwitterIcon />
                )}

                {links.facebook ? (
                  <a href={links.facebook} target="_blank">
                    <FacebookIcon />
                  </a>
                ) : (
                  <FacebookIcon />
                )}

                {links.instagram ? (
                  <a href={links.instagram} target="_blank">
                    <InstagramIcon />
                  </a>
                ) : (
                  <InstagramIcon />
                )}
              </div>
            </div>
          </div>
        )}
        {associatedEvents.length > 0 && (
          <div className="container-secction container-detail-event">
            {!previewEvent && (
              <div className="container-title-another-events">
                <Typography>
                  <span className="title-event-detail-event">
                    Otros eventos que te pueden interesar
                  </span>
                </Typography>
              </div>
            )}
            <CarouselEventDetailEvent
              events={associatedEvents}
              setShowLoader={setShowLoader}
              handleShowDetailEventPage={handleShowDetailEvent}
              setEventDetailData={setEventDetailDataLocal}
            />
          </div>
        )}
        <div className="container-footer-register-event container-secction container-detail-event">
          <div className="container-btn-register-event">{price}</div>
          <div className="container-btn-register-event">
            <Button
              className="btn-register-phone-event"
              type="submit"
              variant="contained"
              color="secondary"
            >
              REGISTRO
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetailForm;
