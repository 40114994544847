import React from 'react';
import RigthArrow from '../../assets/img/rigth-arrow.svg';

const CustomRightArrow = ({ onClick, ...rest }) => {
  return (
    <button type='button' className='arrow-custom arrow-right-custom' onClick={() => onClick()}>
      <img src={RigthArrow} alt='' />
    </button>
  );
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  return (
    <button type='button' className='arrow-custom arrow-left-custom' onClick={() => onClick()}>
      <img src={RigthArrow} alt='' />
    </button>
  );
};

export { CustomRightArrow, CustomLeftArrow };
