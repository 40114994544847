import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import Calendar from '../../assets/img/calendar.svg';
import { deleteEvent } from '../../services/event.services';

const DeleteEvent = (props) => {
  const {
    show,
    closeDeleteEvent,
    setShowLoader,
    handleConfirmDeleteEvent,
    id,
  } = props;
  const user = useSelector((state) => state.user);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const deleteEventClick = async () => {
    setShowLoader(true);

    deleteEvent(id, user.id)
      .then(() => {
        handleConfirmDeleteEvent();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setShowLoader(false);
        closeDeleteEvent();
      });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={closeDeleteEvent}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContent>
            <Grid
              container
              component="main"
              className="container-image-delete-event"
            >
              <Grid item xs={12} sm={12} md={12} xl={12} className="">
                <img src={Calendar} alt="" />
              </Grid>
            </Grid>
          </DialogContent>
          <Typography className="text-delete-event">
            ¿ Estás seguro de eliminar este evento ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container component="main" className="">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              className="container-delete-event-btn"
            >
              <Button
                className="cancel-delete-event-btn"
                type="button"
                variant="contained"
                onClick={closeDeleteEvent}
              >
                CANCELAR
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={deleteEventClick}
              >
                ELIMINAR
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteEvent;
