import { Typography } from '@material-ui/core';
import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ItemList = (props) => {

  const {
    name,
    value,
    handleDeleteItem,
  } = props;

  return (
    <>
      <section className='category-selected'>
        <div className='container-title-category'>
          <Typography className='label-items-selected'>
            {name}
          </Typography>
        </div>
        <HighlightOffIcon onClick={() => handleDeleteItem(value)} />
      </section>
    </>
  );
};

export default ItemList;
