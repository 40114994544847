import React from 'react';
import { Grid } from '@material-ui/core';
import ItemList from './ItemList';

const ListItemsSelected = (props) => {

  const {
    items,
    handleDeleteItem,
  } = props;

  return (
    <>
      <Grid container component='div'>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          {
            items.map((item) => {
              return (
                <ItemList key={`Item_selected_${item.value}`} name={item.name} value={item.value} handleDeleteItem={handleDeleteItem} />
              );
            })
          }
        </Grid>
      </Grid>
    </>
  );
};

export default ListItemsSelected;
