import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router';
import '../assets/styles/containers/User.scss';
import MessageSuccess from '../components/User/MessageSuccess';
import ROUTES from '../utils/RouteUtils';

const User = (props) => {

  const location = useLocation().pathname;

  const handleRedirectProfile = () => {
    props.history.push(ROUTES.PROFILE);
  };

  const handleRedirectEvents = () => {
    props.history.push(ROUTES.MY_EVENTS);
  };

  return (
    <>
      <Grid container component='main' className='container-header-page-url'>
        <Grid item xs={12} sm={12} md={3} xl={2}>
          <div>
            <Typography>
              <span className='text-header-page-url'>
                Inicio / Mi perfil
              </span>
            </Typography>
          </div>
          <div>
            <Typography>
              <span className='text-title-page-url'>
                Mi perfil
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9} xl={10} className='container-header-page-url'>
          {props.message_success && (
            <MessageSuccess />
          )}
        </Grid>
      </Grid>
      <div className='container-component'>
        <Grid container component='main'>
          <Grid item xs={12} sm={12} md={3} xl={2} className='container-menu-profile-bash'>
            <div className='container-profile-bash'>
              <div className='container-list-tabs-bash'>
                <div className={`tab-user-bash ${location === ROUTES.PROFILE ? 'tab-user-select-bash' : ''}`} onClick={handleRedirectProfile}>
                  <Typography>
                    <span>
                      Mis datos
                    </span>
                  </Typography>
                </div>
                <div className={`tab-user-bash ${location === ROUTES.MY_EVENTS ? 'tab-user-select-bash' : ''}`} onClick={handleRedirectEvents}>
                  <Typography>
                    <span>
                      Mis eventos
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={9} xl={10}>
            <div className='container-children-profile'>
              {props.children}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapPropsToDispatch = (state) => {
  return {
    message_success: state.message_success,
  };
};

export default connect(mapPropsToDispatch, null)(withRouter(User));
