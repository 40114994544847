import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { getProfile } from '../../services/user.services';
import { setUser, logoutUser } from '../../actions';
import ROUTES from '../../utils/RouteUtils';

const AuthUser = ({ children, user, setUser, logoutUser }) => {
  const history = useHistory();

  const setUserInformation = async () => {
    const response = await getProfile(user.token);
    if (response.status) {
      setUser({
        id: response.id,
        name: response.name,
        email: response.email,
        image: response.image,
      });
    } else {
      logoutUser();
    }
  };

  useEffect(() => {
    setUserInformation();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length === 0) history.push(ROUTES.READY_LOGIN);
  }, [user]);

  return children;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setUser,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthUser);
