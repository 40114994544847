import React from 'react';
import { Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core';

import '../../../assets/styles/components/ItemInformation.scss';
import FamilyImg from '../../../assets/img/familily.png';

const ItemCategories = (props) => {

  const {
    id,
    img,
    title,
    handleSelectItemId,
    selectedCategories,
  } = props;

  const [checked, setChecked] = React.useState(selectedCategories[id]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleSelectItemId(id, event.target.checked);
  };

  const handleSelectedItem = () => {
    setChecked(!checked);
    handleSelectItemId(id);
  };

  return (
    <>
      <Paper className='item-categorie-new-event' elevation={3} style={{borderRadius: '21px'}} onClick={handleSelectedItem}>
        <div className='checked-information'>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checked || false}
                onChange={handleChange}
                name='item-1'
                color='primary'
              />
            )}
          />
        </div>
        <div className='img-categorie'>
          <img alt='' src={img || FamilyImg} />
        </div>
        <div className='name-categorie'>
          <Typography>
            <span>
              {title || 'Familia'}
            </span>
          </Typography>
        </div>
      </Paper>
    </>
  );
};

export default ItemCategories;
