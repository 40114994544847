import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import { ExitToApp } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logoutUser } from '../../actions/index';
import ROUTES from '../../utils/RouteUtils';
import { logOut } from '../../services/authentication-services';

const CustomMenu = (props) => {
  const {
    anchorEl,
    handleClose,
    logoutUser,
    user,
  } = props;

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const logoutUserMenu = () => {
    console.log('LogOut User desktop');
    logOut(user.token);
    logoutUser();
    handleClose();
    props.history.push(ROUTES.HOME);
  };

  const profileUser = () => {
    console.log('Profile');
    handleClose();
    props.history.push(ROUTES.PROFILE);
  };

  return (
    <>
      <StyledMenu
        className='menu-containe-desktop'
        id='customized-menu-bash'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={profileUser}>
          <ListItemIcon>
            <PersonIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Perfil' />
        </StyledMenuItem>
        <StyledMenuItem onClick={logoutUserMenu}>
          <ListItemIcon>
            <ExitToApp fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Cerrar Sesión' />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  logoutUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomMenu));

