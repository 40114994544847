import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import GoogleIcon from '../../assets/img/google-icon.svg';
import { loginGoogle } from '../../actions/index';

const LoginGoogle = (props) => {
  const {
    loginGoogle,
    loginSocialSuccess,
    handleSetErrorState,
    handleSetNoErrorState,
  } = props;

  const [client, setClient] = useState(null);
  const keyGoogle = process.env.REACT_APP_GOOGLE_APP_ID;
  const google = window.google ?? {};

  const responseGoogle = (response) => {
    handleSetNoErrorState();
    loginGoogle(response, loginSocialSuccess);
  };

  const handleErrorLogin = (response) => {
    handleSetErrorState();
  };

  const handleLoginGoogle = () => {
    client.requestAccessToken();
  }

  useEffect(() => {

    const client = google.accounts.oauth2.initTokenClient({
      client_id: keyGoogle,
      scope: 'profile email',
      callback: (response) => {
        try {
          if (!response || !response.access_token)
            throw Error('User is not authenticated');

          const result = {
            provider: 'google',
            access_token_social: response.access_token,
          };

          loginSocialSuccess(result);
        } catch (error) {
          console.error(error);
        }
      },
    });

    setClient(client);
  }, []);

  return (
    <Button
      className="btn-google"
      type="button"
      onClick={handleLoginGoogle}
    >
      <div className="btn-icon-with-text-center">
        <img className="google" src={GoogleIcon} alt="" />
        <Typography>INGRESA CON GOOGLE</Typography>
      </div>
    </Button>
  );
};

const mapDispatchToProps = {
  loginGoogle,
};

export default connect(null, mapDispatchToProps)(LoginGoogle);
