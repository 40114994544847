import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import DefaultImg from '../../assets/img/img-background-profile.png';

const DropZone = (props) => {

  const {
    img,
    setImage,
    setLoading,
  } = props;

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      if (setLoading) setLoading(true);
      setTimeout(() => {
        toBase64(acceptedFiles[0])
          .then((imgB64) => {
            setImage(imgB64, acceptedFiles[0]);
          }).catch((error) => {
            console.log('Ha ocurrido un error al actualizar la imagen');
            console.log(error);
          }).finally(() => {
            if (setLoading) setLoading(false);
          });
      }, 2000);
    } else {
      console.log('Solo puede cargar una imagen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg',
    multiple: false,
  });

  return (
    <>
      <div {...getRootProps()} className='drop-zone-img-bash'>
        <input {...getInputProps()} />
        <img src={img || DefaultImg} alt='' />
        <Typography><span>Jpg/Png</span></Typography>
        <div className='action-upload-img'>
          <Typography><span>Subir foto de perfil</span></Typography>
        </div>
      </div>
    </>
  )
}
export default DropZone;
