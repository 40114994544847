import React from 'react';
import { withRouter } from 'react-router';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import ROUTES from '../../utils/RouteUtils';

const NewEventButton = (props) => {

  const {
    user,
  } = props;

  const handleNewEvent = () => {
    if (user.token) {
      console.log('CREATE NEW EVENT');
      props.history.push(ROUTES.NEW_EVENT);
    } else {
      props.history.push(ROUTES.READY_LOGIN);
    }
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        startIcon={<AddCircleIcon />}
        onClick={handleNewEvent}
      >
        <Typography>Crear Evento</Typography>
      </Button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps, null)(NewEventButton));
