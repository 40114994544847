import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PublicGlobeIcon from '@material-ui/icons/Public';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import {
  getLabelsEvent,
  getTypeEvents,
} from '../../../services/event.services';
import AutocompleteSearch from '../../CustomBashComponents/AutocompleteSearch';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import { EVENT_TYPE, LANGUAGES } from '../../../utils';

const StepBasicInformation = (props) => {
  const { form, handleChange, setLoading, categories, id } = props;
  const CHARACTER_NAME_LIMIT = 75;

  const handleAddLabel = (label) => {
    console.log(`Se va a agregar ${label}`);

    form.labels.push(label);

    console.log(form);

    handleChange({
      target: {
        name: 'labels',
        value: form.labels,
      },
    });
  };

  const handleDeleteItem = (itemId) => {
    form.labels = form.labels.filter((item) => {
      return item.value !== itemId;
    });

    handleChange({
      target: {
        name: 'labels',
        value: form.labels,
      },
    });
  };

  const handleChangeLocation = (location) => {
    handleChange({ target: { name: 'location', value: location } });
  };

  const handleStartDateChange = (date) => {
    handleChange({ target: { name: 'startDate', value: date } });
  };

  const handleEndDateChange = (date) => {
    handleChange({ target: { name: 'endDate', value: date } });
  };

  const handleStartHourChange = (date) => {
    handleChange({ target: { name: 'startHour', value: date } });
  };

  const handleEndHourChange = (date) => {
    handleChange({ target: { name: 'endHour', value: date } });
  };
  return (
    <>
      <Typography className="title-step">
        {id ? 'Nombre del evento' : 'Comencemos con el nombre del evento'}
      </Typography>
      <TextField
        className="input-step"
        inputProps={{
          maxLength: CHARACTER_NAME_LIMIT,
        }}
        onChange={handleChange}
        color="primary"
        id="name"
        label="Nombre de evento"
        helperText={`${form.name.length}/${CHARACTER_NAME_LIMIT}`}
        name="name"
        type="text"
        value={form.name}
        required
      />

      <Typography className="title-step">Tipo de evento</Typography>
      {categories && (
        <Select
          labelId="label"
          id="type-new-event"
          name="type"
          value={form.type}
          className="input-step"
          onChange={handleChange}
          color="primary"
          IconComponent={ExpandMoreIcon}
          required
        >
          <MenuItem value="">
            <em>Selecciona un evento</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={`event_type_${category.id}`} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      )}

      <Typography className="sub-title-step">
        Selecciona etiquetas, para que los asitente encuentre tu evento
      </Typography>
      <div className="section-input">
        <AutocompleteSearch
          handleSearchService={getLabelsEvent}
          labelsSelected={form.labels}
          handleAddLabel={handleAddLabel}
          handleDeleteItem={handleDeleteItem}
          keyId="search-types"
        />
      </div>

      <Typography className="sub-title-step">
        Selecciona el idioma de tu evento
      </Typography>
      <Select
        labelId="label"
        id="type-lenguaje"
        name="language"
        value={form.language}
        className="input-step"
        onChange={handleChange}
        color="primary"
        IconComponent={ExpandMoreIcon}
        required
      >
        <MenuItem value={LANGUAGES.spanish}>Español</MenuItem>
        <MenuItem value={LANGUAGES.english}>English</MenuItem>
        <MenuItem value={LANGUAGES.portuguese}>Português</MenuItem>
      </Select>

      <hr />

      <Typography className="title-step mt-3">Ubicación</Typography>
      <Grid container component="section">
        <Grid item xs={12} sm={12} md={12} xl={12} className="mt-3">
          <Box display="flex" justifyContent="center">
            <Box className="box-location-btns">
              <Button
                className={
                  form.location === EVENT_TYPE.online ? 'btn-selected' : ''
                }
                variant="outlined"
                color="primary"
                onClick={() => handleChangeLocation(EVENT_TYPE.online)}
              >
                <Typography className="btn-outline-bash-title">
                  Online
                </Typography>
              </Button>
            </Box>
            <Box className="box-location-btns">
              <Button
                className={
                  form.location === EVENT_TYPE.in_person ? 'btn-selected' : ''
                }
                variant="outlined"
                color="primary"
                onClick={() => handleChangeLocation(EVENT_TYPE.in_person)}
              >
                <Typography className="btn-outline-bash-title">
                  Lugar
                </Typography>
              </Button>
            </Box>
            <Box className="box-location-btns">
              <Button
                className={
                  form.location === EVENT_TYPE.to_be_defined
                    ? 'btn-selected'
                    : ''
                }
                variant="outlined"
                color="primary"
                onClick={() => handleChangeLocation(EVENT_TYPE.to_be_defined)}
              >
                <Typography className="btn-outline-bash-title">
                  Por definir
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        {form.location === EVENT_TYPE.in_person && (
          <Grid item xs={12} sm={12} md={12} xl={12} className="mt-3">
            <TextField
              className="input-step"
              onChange={handleChange}
              color="primary"
              id="input-location-instructions"
              label="Ingresa dirección"
              name="locationInstructions"
              type="text"
              value={form.locationInstructions}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
          </Grid>
        )}
        {form.location === EVENT_TYPE.online && (
          <Grid item xs={12} sm={12} md={12} xl={12} className="mt-3">
            <TextField
              className="input-step"
              onChange={handleChange}
              color="primary"
              id="input-location-instructions"
              label="Ingresa el link del evento"
              name="link"
              type="text"
              value={form.link}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PublicGlobeIcon />
                  </InputAdornment>
                ),
              }}
              required
            />
          </Grid>
        )}
      </Grid>

      <Typography className="title-step mt-3">Fecha y Hora</Typography>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container component="section">
          <Grid item xs={12} sm={12} md={6} xl={6} className="mt-3">
            <KeyboardDatePicker
              className="input-step"
              margin="normal"
              id="date-picker-dialog"
              label="Fecha Inicio"
              format="dd/MM/yyyy"
              value={form.startDate}
              name="startDate"
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} className="mt-3">
            <KeyboardDatePicker
              className="input-step"
              margin="normal"
              id="date-picker-dialog"
              label="Fecha finalización"
              format="dd/MM/yyyy"
              value={form.endDate}
              name="endDate"
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} className="mt-3">
            <TimePicker
              className="input-step"
              showTodayButton
              todayLabel="now"
              label="Hora Inicio"
              value={form.startHour}
              minutesStep={5}
              onChange={handleStartHourChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <QueryBuilderIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} className="mt-3">
            <TimePicker
              className="input-step"
              showTodayButton
              todayLabel="now"
              label="Hora Final"
              value={form.endHour}
              minutesStep={5}
              onChange={handleEndHourChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <QueryBuilderIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
  };
};

export default connect(mapStateToProps, null)(StepBasicInformation);
