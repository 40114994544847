import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const FilterModal = (props) => {

  const {
    open,
    handleClose,
    interests,
    activeSections,
    onFilter,
    form,
    handleChange,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [actualSections, setActualSections] = useState({});

  const [rangePrices, setRangePrices] = React.useState([form.minPrice, form.maxPrice]);

  const handleRangePrices = async (event, newValue) => {
    setRangePrices(newValue);

    if (form.minPrice !== newValue[0]) {
      handleChange({ target: { name: 'minPrice', value: newValue[0] } });
    } else if (form.maxPrice !== newValue[1]) {
      handleChange({ target: { name: 'maxPrice', value: newValue[1] } });
    }
  };

  const handleOnCloseFilter = () => {

    handleChange({ target: { name: 'minPrice', value: rangePrices[0] } });
    handleChange({ target: { name: 'maxPrice', value: rangePrices[1] } });
    onFilter(actualSections);
  };

  const handleSelectChange = (event) => {
    const newsections = {
      ...actualSections,
      [event.target.name]: event.target.checked,
    };

    setActualSections(newsections);
  };

  const formatNumber = (price) => {
    return new Intl.NumberFormat('EN', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  };

  useEffect(() => {
    setActualSections(activeSections);
  }, [activeSections]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        scroll='body'
      >
        <DialogTitle className='title-select-categories'>
          <Typography className='title-filter'>
            Busca tus eventos
          </Typography>
        </DialogTitle>
        <DialogContent scroll='body' dividers={false}>
          <Grid container component='section'>
            {interests && (
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography className='subtitle-filter'>Selecciona tus Intereses</Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <FormGroup row>
                { interests &&
                  interests.map((interest) => {
                    return (
                      <Grid item xs={4} sm={4} md={4} xl={4} key={`interest_${interest.id}`}>
                        <FormControlLabel control={<Checkbox checked={actualSections[interest.id]} onChange={handleSelectChange} name={interest.id} inputProps={{ 'aria-label': 'controlled' }} />} label={interest.name} />
                      </Grid>
                    );
                  })}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6} className='mt-3'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className='date-picker-grid'
                  id='date-picker-dialog-start-date'
                  label='Fecha Inicio (desde)'
                  format='dd/MM/yyyy'
                  color='secondary'
                  value={form.startDate}
                  name='startDate'
                  onChange={(date) => handleChange({ target: { name: 'startDate', value: date } })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6} className='mt-3'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className='date-picker-grid'
                  id='date-picker-dialog-end-date'
                  label='Fecha Inicio (hasta)'
                  format='dd/MM/yyyy'
                  color='secondary'
                  value={form.endDate}
                  name='endDate'
                  onChange={(date) => handleChange({ target: { name: 'endDate', value: date } })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} className='mt-3'>
              <Typography className='subtitle-filter'>Rango de precios</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Slider
                color='secondary'
                value={rangePrices}
                onChange={handleRangePrices}
                valueLabelDisplay='auto'
                aria-labelledby='range-slider'
                min={0}
                step={50}
                max={1000}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} className='mt-1'>
              <Typography className=''>
                Precio Minimo:
                {' '}
                {formatNumber(rangePrices[0])}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} className=''>
              <Typography className=''>
                Precio Maximo:
                {' '}
                {formatNumber(rangePrices[1])}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} className='mt-3'>
              <Typography className='subtitle-filter'>Idioma</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <RadioGroup aria-label='lenguaje' name='lenguaje' value={form.lenguaje} onChange={handleChange}>
                <FormControlLabel value='ES' control={<Radio />} label='Español' />
                <FormControlLabel value='EN' control={<Radio />} label='English' />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container component='section'>
            <Grid item xs={12} sm={12} md={12} xl={12} className='container-selected-categories'>
              <Button type='button' className='btn-login-bash' variant='contained' color='primary' onClick={handleOnCloseFilter}>FILTRAR</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapPropsToDispatch = (state) => {
  return {
  };
};

export default connect(mapPropsToDispatch, null)(FilterModal);
