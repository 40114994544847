import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextareaAutosize, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { sendMenssage } from '../../services/event.services';

const SendMesssageEvent = (props) => {

  const [form, setForm] = useState({
    message: '',
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    listIdsUsers,
    show,
    handleCloseMessage,
    setShowLoader,
  } = props;

  const sendMessage = () => {
    setShowLoader(true);

    sendMenssage(listIdsUsers, form.message)
      .then((success) => {
        console.log(success);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setShowLoader(false);
        handleCloseMessage();
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleCloseMessage}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className='container-title-msg-send'>
          <Typography className='title-msg-send'>
            Nuevo mensaje
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <Grid container component='main' className='container-image-delete-event'>
              <Grid item xs={12} sm={12} md={12} xl={12} className=''>
                <Typography>
                  Escribele un mensaje a tus usuarios
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className=''>
                <TextareaAutosize className='text-area-send-message' rowsMin={6} id='message' name='message' placeholder='Escribe tu mensaje' value={form.message} onChange={handleChange} />
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Grid container component='main' className=''>
            <Grid item xs={12} sm={12} md={12} xl={12} className='container-delete-event-btn'>
              <Button className='cancel-delete-event-btn' type='button' variant='contained' onClick={handleCloseMessage}>CANCELAR</Button>
              <Button type='button' variant='contained' color='primary' onClick={sendMessage}>ENVIAR MENSAJE</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendMesssageEvent;
