/**
 *
 * @param {Date} date
 * @returns string
 */
export const formatDate = (date, zeroHour = false) => {
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return `${ye}-${mo}-${da}${zeroHour ? ' 00:00:00' : ''}`;
};

/**
 *
 * @param {Date} date
 * @returns string
 */
export const formatDateTime = (date) => {
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  let hourMinutes = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  }).format(date);
  return `${ye}-${mo}-${da} ${hourMinutes}`;
};

/**
 *
 * @param {Date} date
 * @param {Date} time
 * @returns string
 */
export const buildDateTime = (date, time) => {
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  const timeStr = time.toLocaleTimeString('it-IT');

  return `${ye}-${mo}-${da} ${timeStr}`;
};
