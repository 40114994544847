import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ENDPOINT_BASE;

export const validateEmail = (email) => {
  const body = {
    email,
  };

  return axios.post(`${BASE_URL}/check-register`, body);
};

export const loginUserPassword = (email, password) => {
  const body = {
    email,
    password,
  };

  return axios.post(`${BASE_URL}/login`, body);
};

export const registerUser = (dataUser) => {
  const body = {
    email: dataUser.email,
    password: dataUser.password,
    password_confirmation: dataUser.password,
    name: dataUser.name,
  };

  return axios.post(`${BASE_URL}/register`, body);
};

export const socialLogin = (dataUser) => {
  const body = {
    access_token: dataUser.access_token_social,
    provider: dataUser.provider,
  };

  return axios.post(`${BASE_URL}/social-login`, body);
};

export const logOut = async (token) => {
  try {
    const config = {
      headers: { authorization: `Bearer ${token}` },
    };
    const requestData = {
      all_devices: '1',
    };

    console.log(config);
    console.log(requestData);
    const response = await axios.post(
      `${BASE_URL}/logout`,
      requestData,
      config
    );

    console.log('Success logout');
    console.log(response);
  } catch (e) {
    console.log('Error en logout');
    console.log(e.response);
  }
};

export const forgotPassword = async (email) => {
  try {
    const body = { email };
    const response = await axios.post(`${BASE_URL}/forgot-password`, body);

    return { status: true, message: 'Se ha enviado un email de recuperación de contraseña' };
  } catch (error) {
    return { status: false, message: "We can't find a user with that email address", error };
  }
};
