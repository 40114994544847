import React, { useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ROUTES from '../../utils/RouteUtils';
import LoginFB from './LoginFB';
import LoginGoogle from './LoginGoogle';
import { socialLogin, validateEmail } from '../../services/authentication-services';
import { setEmail, setTokenSocial } from '../../actions';

import '../../assets/styles/components/Athentication/Login.scss';
import Loading from '../Loading';
import { Link } from 'react-router-dom';

const ReadyLogin = (props) => {

  const {
    setEmail,
    setTokenSocial,
  } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: '',
  });

  const handleLogin = () => {
    props.history.push(ROUTES.HOME);
  };

  const handleSetErrorState = () => {
    setError(true);
  };

  const handleSetNoErrorState = () => {
    setError(false);
  };

  const loginSocialSuccess = (data) => {
    console.log('Login Social Success');
    setLoading(true);
    socialLogin(data)
      .then((response) => {
        setTokenSocial(response.data.data.token);
        handleLogin();
      }).catch((error) => {
        console.log('error');
        console.log(error.response);
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const submitForm = (event) => {
    setLoading(true);
    event.preventDefault();
    console.log('On submit form');
    setEmail(form.email);
    validateEmail(form.email)
      .then((response) => {
        if (response.data.data.is_registered) {
          props.history.push(ROUTES.LOGIN);
        } else {
          props.history.push(ROUTES.REGISTER);
        }
      }).catch((error) => {
        console.log(error.data);
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <Typography><span className='welcome-bash'>Bienvenido</span></Typography>
        <Typography className='text-welcome-register'>Registrate o inicia sesión</Typography>
      </div>
      <form id='ready_form' nameç='ready_form' className='' autoComplete='off' onSubmit={submitForm}>
        <div className='container-input'>
          <TextField
            onChange={handleChange}
            error={error}
            color='primary'
            className='input-login'
            id='email'
            label='email'
            name='email'
            type='email'
            required
            helperText={error ? 'Error en los datos de login' : ''}
          />
        </div>
        <Button type='submit' className='btn-login-bash' variant='contained' color='primary'>INICIAR</Button>
      </form>
      <div className='text-login-social'>
        <Typography>o</Typography>
      </div>
      <LoginFB
        loginSocialSuccess={loginSocialSuccess}
        handleSetErrorState={handleSetErrorState}
        handleSetNoErrorState={handleSetNoErrorState}
      />
      <LoginGoogle
        loginSocialSuccess={loginSocialSuccess}
        handleSetErrorState={handleSetErrorState}
        handleSetNoErrorState={handleSetNoErrorState}
      />
      <div className='row remind-password-section mt-2'>
        <Link className='remind-password' to={ROUTES.RESET_PASSWORD}>
          <span>No recuerdas tu contraseña?</span>
        </Link>
      </div>
      {loading && <Loading />}
    </>
  );
};

const mapDispatchToProps = {
  setEmail,
  setTokenSocial,
};

const mapPropsToDispatch = (state) => {
  return {
  };
};

// withRouter(ReadyLogin);

export default connect(mapPropsToDispatch, mapDispatchToProps)(ReadyLogin);
