import {
  Button,
  createMuiTheme,
  Grid,
  makeStyles,
  MobileStepper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  ThemeProvider,
  withStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getListCategories, setAlert } from '../../actions';
import ActiveStep from '../../assets/img/active-step.svg';
import CompleteStep from '../../assets/img/complete-step.svg';
import PostStep from '../../assets/img/post-step.svg';
import '../../assets/styles/components/Event/CreateNewEvent.scss';
import { saveUpdateEvent } from '../../services/event.services';
import {
  CURRENCY,
  EVENT_TYPE,
  getLocationInstructions,
  getLocationLinks,
  getLocationType,
  LANGUAGES,
} from '../../utils';
import ROUTES from '../../utils/RouteUtils';
import Loading from '../Loading';
import StepBasicInformation from './CreateEventSteps/StepBasicInformation';
import StepImage from './CreateEventSteps/StepImage';
import StepPreviewEvent from './CreateEventSteps/StepPreviewEvent';
import StepPrice from './CreateEventSteps/StepPrice';

const completeStep = {
  icon: <img src={CompleteStep} alt="" className="icon-step" />,
};
const activeStepIcon = {
  icon: <img src={ActiveStep} alt="" className="icon-step" />,
};
const postStep = { icon: <img src={PostStep} alt="" className="icon-step" /> };

const CreateNewEvent = (props) => {
  const { user, event, eventId, loadingScreen, getListCategories, setAlert } =
    props;

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [form, setForm] = useState({
    name: '',
    type: '',
    labels: [],
    language: LANGUAGES.spanish,
    location: EVENT_TYPE.online,
    locationInstructions: '',
    link: '',
    startDate: new Date(),
    endDate: new Date(),
    startHour: new Date(),
    endHour: new Date(),
    image: null,
    imageFile: null,
    description: '',
    materials: [],
    isFree: true,
    price: '0',
    currency: CURRENCY.COP,
    facebook: '',
    twitter: '',
    instagram: '',
  });

  const steps = ['Datos básicos', 'Detalles', 'Entrada', 'Resumen'];

  const ColorlibConnector = withStyles({
    active: { '& $line': { backgroundColor: '#EFEFF3' }, color: 'green' },
    completed: { '& $line': { backgroundColor: '#EFEFF3' } },
    line: {
      height: 2,
      border: 0,
      backgroundColor: '#747779',
      borderRadius: 1,
    },
  })(StepConnector);

  const classes = makeStyles({
    root: { backgroundColor: '#FFFFFF' },
    progress: {
      width: '100%',
      backgroundColor: '#747779',
      height: '8px',
      borderRadius: '8px',
    },
  })();

  const getStepIcon = (indexStep) => {
    return indexStep < activeStep
      ? completeStep
      : indexStep === activeStep
      ? activeStepIcon
      : postStep;
  };

  const handleBackPage = () => {
    props.history.goBack();
  };

  const handleNext = () => {
    if (activeStep < steps.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    console.log(event);
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <StepBasicInformation
            form={form}
            id={id}
            handleChange={handleChange}
            setLoading={setLoading}
          />
        );
      case 1:
        return (
          <StepImage
            form={form}
            setLoading={setLoading}
            handleChange={handleChange}
          />
        );
      case 2:
        return <StepPrice form={form} handleChange={handleChange} />;
      default:
        return <StepPreviewEvent form={form} user={user} />;
    }
  };

  const getStepName = (currentStep) => {
    return steps[currentStep];
  };

  const saveEvent = (publish) => {
    setLoading(true);
    saveUpdateEvent(form, publish, user.id, eventId)
      .then((success) => {
        setLoading(false);
        setAlert({
          severity: 'success',
          message: `Evento ${publish ? 'publicado' : 'guardado'} correctamente.`,
        });
        props.history.push(ROUTES.MY_EVENTS);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        setAlert({severity: 'error', message: 'El evento no pudo ser guardado, intente nuevamente.'})
      });
  };

  const setEventToEdit = () => {
    setId(event.id);
    setForm({
      ...form,
      name: event.name,
      type: event.categories ? event.categories[0]['id'] : '',
      language: event.language,
      location: getLocationType(event.location),
      locationInstructions: getLocationInstructions(event.location),
      link: getLocationLinks(event.location),
      startDate: new Date(event.start_date) ?? new Date(),
      endDate: new Date(event.end_date) ?? new Date(),
      startHour: new Date(event.start_date) ?? new Date(),
      endHour: new Date(event.end_date) ?? new Date(),
      image: event.image,
      description: event.description,
      facebook: event.links ? event.links.facebook : '',
      twitter: event.links ? event.links.twitter : '',
      instagram: event.links ? event.links.instagram : '',
      isFree: !event.payment_required,
      currency: event.payment_required
        ? event.payment_description.currency
        : CURRENCY.COP,
      price: event.payment_required ? event.payment_description.amount : '0',
    });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  useEffect(() => {
    if (event) {
      setEventToEdit();
    }
  }, [event]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  useEffect(() => {
    getListCategories({ token: user.token });
  }, []);

  return (
    <>
      <Grid container component="main" className="">
        <Grid item xs={12} sm={12} md={1} xl={1} />
        <Grid item xs={12} sm={12} md={10} xl={10}>
          <div className="mobile-back-btn container-create-event">
            <ArrowBackIcon className="btn-back-page" onClick={handleBackPage} />
          </div>
          <div className="container-create-event">
            <div className="desktop-steper">
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={getStepIcon(index)}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="mobile-steper">
              <ThemeProvider
                theme={(theme) =>
                  createMuiTheme({
                    ...theme,
                    palette: {
                      ...theme.palette,
                      primary: {
                        main: '#FFD200',
                      },
                    },
                  })
                }
              >
                <MobileStepper
                  variant="progress"
                  steps={steps.length}
                  position="static"
                  activeStep={activeStep}
                  classes={classes}
                />
              </ThemeProvider>
              <div className="content-name-step">{getStepName(activeStep)}</div>
            </div>
            <div>
              <div className="container-steps">
                {getStepContent(activeStep)}
              </div>
              <div className="mb-3 group-btns-steps">
                {activeStep !== 0 && (
                  <Button
                    className="btn-create-event back-btn-step"
                    type="button"
                    variant="contained"
                    onClick={handleBack}
                  >
                    ATRÁS
                  </Button>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    className="btn-create-event"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    SIGUIENTE
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <>
                    <Button
                      className="btn-create-event back-btn-step"
                      type="button"
                      variant="contained"
                      onClick={() => saveEvent(false)}
                    >
                      GUARDAR
                    </Button>
                  </>
                )}
                {activeStep === steps.length - 1 && (
                  <>
                    <Button
                      className="btn-create-event"
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => saveEvent(true)}
                    >
                      PUBLICAR
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={1} xl={1} />
      </Grid>
      {loading && (
        <div className="container-loader-create-event">
          <Loading />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  getListCategories,
  setAlert,
};

const mapStateToProps = (state) => {
  return {
    loadingScreen: state.loading_screen,
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewEvent);
