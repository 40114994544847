import axios from 'axios';
import { getConfigToken } from '../utils';

const BASE_URL = process.env.REACT_APP_ENDPOINT_BASE;

export const getProfile = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };

  try {
    const response = await axios.get(`${BASE_URL}/user/profile`, config);

    return {
      status: true,
      id: response.data.data.id,
      name: response.data.data.name,
      email: response.data.data.email,
      image: response.data.data.image,
      password: 'XXXXXXXX',
    };
  } catch (error) {
    return {
      status: false,
      msg: 'ha ocurrido un error',
    };
  }
};

export const setProfile = async (dataForm, token) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'multipart/form-data',
    },
  };
  const requestData = new FormData();

  if (dataForm.image) {
    requestData.append('image', dataForm.image);
  }
  requestData.append('name', dataForm.name);
  requestData.append('email', dataForm.email);

  console.log(config);
  console.log(requestData);
  return axios.post(`${BASE_URL}/user/profile`, requestData, config);
};

export const changePassword = async (dataForm, email, token) => {
  const data = {
    email: email,
    token: token,
    //password: dataForm.old_password,
    password: dataForm.new_password,
    password_confirmation: dataForm.new_password_confirm,
  };

  console.log(data);

  if (data.password !== data.password_confirmation)
    return { status: false, message: 'Las contraseñas deben coincidir' };

  try {
    const config = getConfigToken(null, token);
    const response = await axios.post(
      `${BASE_URL}/reset-password`,
      data,
      config
    );

    return {
      status: true,
      message: 'La contraseña se actualizó de forma exitosa',
    };
  } catch (error) {
    return { status: false, message: 'La contraseña no pudo ser actualizada' };
  }
};

export const getPreferences = () => {
  const data = {
    preferences: [
      {
        id: 0,
        title: 'Deporte',
      },
      {
        id: 1,
        title: 'Niños',
      },
      {
        id: 2,
        title: 'Adultos',
      },
      {
        id: 3,
        title: 'Meditación',
      },
      {
        id: 4,
        title: 'Concierto',
      },
      {
        id: 5,
        title: 'Música',
      },
      {
        id: 6,
        title: 'Comedia',
      },
      {
        id: 7,
        title: 'Gatronomia',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 4000);
  });
};
