import React from 'react';
import '../../assets/styles/containers/Footer.scss';
import { Grid, Typography } from '@material-ui/core';

import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { useHistory } from "react-router-dom";

const Footer = function () {

  const history = useHistory();
  const redirectToAboutUs = () => {
    history.push('/about-us');
  }
  const redirectToTermsAndConditions = () => {
    history.push('/terms-and-conditions');
  }

  return (
    <>
      <footer className='nav-footer-bash'>
        <div className='img-map-container '>
          <div className='container-footer'>
            <Grid container component='main' className=''>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className='container-sections-footer'>
                <Typography className='title-bash-footer'>Bash</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2} className='container-sections-footer'>
                <Typography className='title-seccions-footer'>
                  Ayuda
                </Typography>
                <Typography className='list-text-seccion'>
                  contacto@bash.com
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2} className='container-sections-footer'>
                <Typography className='title-seccions-footer'>
                  Información útil
                </Typography>
                <Typography onClick={redirectToAboutUs} className='list-text-seccion cursor-pointer'>
                  Quienes somos
                </Typography>
              </Grid>
              <Grid item xs={false} sm={false} md={4} lg={false} xl={false} className='container-blank-space container-sections-footer'></Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2} className='container-sections-footer'>
                <Typography className='title-seccions-footer'>
                  Legales
                </Typography>
                <Typography onClick={redirectToTermsAndConditions} className='list-text-seccion cursor-pointer'>
                  Términos y condiciones
                </Typography>
                {/* <Typography className='list-text-seccion'>
                  Políticas de privacidad
                </Typography> */}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className='container-sections-footer'>
                <Typography className='title-seccions-footer'>
                  Social
                </Typography>
                <div className='container-social-icons'>
                  <TwitterIcon />
                  <FacebookIcon />
                  <InstagramIcon />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </footer>
      <footer className='col-12 text-center copyrigth-bash'>Copyright  2020 Bash.com</footer>
    </>
  );
};

export default Footer;
