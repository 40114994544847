import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setTokenSocial } from '../../actions/index';
import { loginUserPassword } from '../../services/authentication-services';
import ROUTES from '../../utils/RouteUtils';
import Loading from '../Loading';


const Login = (props) => {

  const {
    user,
    setTokenSocial,
  } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: user.email,
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSetErrorState = () => {
    setError(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const submitForm = (event) => {
    setLoading(true);
    event.preventDefault();
    console.log('On submit form login');
    loginUserPassword(form.email, form.password)
      .then((response) => {
        setError(false);
        setTokenSocial(response.data.data.token);
        props.history.push(ROUTES.HOME);
      }).catch((error) => {
        handleSetErrorState();
        console.log(error.response);
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>

        <div className='login-bash'>
          <span className='welcome-bash'>
            Inicia sessión
          </span>
        </div>
      </div>
      <form id='ready_form' name='ready_form' className='' autoComplete='off' onSubmit={submitForm}>
        <div className='container-input'>
          <TextField onChange={handleChange} error={error} color='primary' className='input-login' id='email' label='email' name='email' type='email' value={user.email} disabled required />
          <TextField
            onChange={handleChange}
            error={error}
            color='primary'
            className='input-login'
            id='password'
            label='password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            required
            helperText={error ? 'Error en los datos de login' : ''}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button type='submit' className='btn-login-bash' variant='contained' color='primary'>INGRESAR</Button>
      </form>

      <div className='login-bash forgot-passsword'>
        ¿Olvidaste tu contraseña?
        <span>RECUPERAR CONTRASEÑA</span>
      </div>
      {loading && <Loading />}
    </>
  );

};

const mapPropsToDispatch = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setTokenSocial,
};

export default connect(mapPropsToDispatch, mapDispatchToProps)(Login);
